<template>
    <div class="container">
        <UserChangeProfileImage v-if="profileImageModal" @close="profileImageModal = false" />
        <div flat class="pa-4">
            <v-row>
                <v-col cols="2">
                    <v-tooltip end>
                        <template #activator="{ on, attrs }">
                            <div class="image-container" v-bind="attrs" @click="profileImageModal = true" v-on="on">
                                <AvatarWithPresence
                                    :userId="loggedInUser.userId"
                                    :presenceId="loggedInUser.PresenceId"
                                    :size="80"
                                />
                            </div>
                        </template>
                        <span>{{ $t('userMenu.changePictureTooltip') }}</span>
                    </v-tooltip>
                </v-col>
                <v-col class="d-flex flex-column justify-center" style="gap: 0.5em">
                    <div style="display: flex; flex-direction: column">
                        <span style="font-size: 20px">{{ loggedInUser.userName }}</span>
                        <span>{{ loggedInUser.Title }}</span>
                    </div>
                    <div v-if="presence" :style="textColor">{{ loggedInUser.StatusText }}</div>
                    <div v-else class="text-caption">{{ loggedInUser.email }}</div>
                    <div class="buttons">
                        <button class="profile-button" @click="routeToProfileView()">
                            {{ $t('userMenu.profileButton') }}
                        </button>
                        <DivertContainer v-if="useC1Diverts" :userId="loggedInUser.userId" :refresh="menu" />
                    </div>
                </v-col>
                <v-col cols="2">
                    <div class="d-flex flex-column align-end">
                        <v-switch v-model="showNoti" class="ma-0 pa-0" dense hide-details inset />
                        <span class="text-caption">{{ $t('userMenu.showNotifications') }}</span>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider />
        <v-tabs v-model="model" grow light show-arrows slider-color="secondary">
            <v-tab v-if="brandsActive" href="#tab-0">
                <div class="d-flex align-center">
                    <v-icon>mdi-domain</v-icon>
                    <span class="ml-3">{{ $t('userMenu.brands') }}</span>
                </div>
            </v-tab>
            <v-tab v-if="!useC1Diverts" href="#tab-1">
                <div class="d-flex align-center">
                    <v-icon>mdi-arrow-right</v-icon>
                    <span class="ml-3">{{ $t('userMenu.reference') }}</span>
                </div>
            </v-tab>
            <v-tab href="#tab-2">
                <div v-if="roleBasedLogin" class="d-flex align-center">
                    <v-icon>mdi-account-outline</v-icon>
                    <span cy-data="login" class="ml-3">{{ $t('userMenu.logIn') }}</span>
                </div>
                <div v-else class="d-flex align-center">
                    <v-icon>mdi-account-group-outline</v-icon>
                    <span cy-data="login" class="ml-3">{{ $t('userMenu.logIn') }}</span>
                </div>
            </v-tab>
        </v-tabs>
        <v-divider />
        <v-card flat max-height="80vh" style="overflow-y: scroll">
            <v-tabs-items v-model="model">
                <v-tab-item v-if="brandsActive" value="tab-0">
                    <BrandsQuickAction />
                </v-tab-item>
                <v-tab-item v-if="!useC1Diverts" value="tab-1">
                    <!-- DIVERTS -->
                    <div class="divert-container">
                        <div v-if="showError" class="divert-container-error">{{ $t('userMenu.divertError') }}</div>
                        <DivertList
                            v-if="!showError"
                            :divertOptions="divertOptions"
                            :aeaCustom="aeaCustom"
                            :divLoading="divLoading"
                        />
                    </div>
                </v-tab-item>
                <v-tab-item value="tab-2">
                    <!-- C1 QUEUES -->
                    <div :key="menu" class="queue-container">
                        <RoleManager v-if="roleBasedLogin" :userId="loggedInUser.userId" />
                        <C1QueueManager v-else />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { getPresenceColor } from '@/utils';
    import AvatarWithPresence from '@/components/Global/AvatarWithPresence.vue';
    import DivertContainer from '@/components/Users/UserMenu/Divert/DivertContainer.vue';

    export default {
        components: {
            BrandsQuickAction: () => import('@/components/QuickActions/BrandsQuickAction.vue'),
            DivertList: () => import('@/components/Users/DivertList.vue'),
            C1QueueManager: () => import('@/components/Global/C1QueueManager.vue'),
            RoleManager: () => import('@/components/Global/RoleManager.vue'),
            UserChangeProfileImage: () => import('@/components/Users/UserChangeProfileImage.vue'),
            DivertContainer,
            AvatarWithPresence,
        },
        props: {
            menu: {
                type: Boolean,
            },
        },
        data() {
            return {
                fav: true,
                message: false,
                hints: true,
                currentDivert: null,
                divertOptions: [],
                aeaCustom: [],
                model: 'tab-1',
                divLoading: false,
                showNoti: null,
                profileImageModal: false,
                showError: false,
                errorType: null,
            };
        },
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
                presence: (state) => state.CallDivert.presence,
                storeDivertOptions: (state) => state.CallDivert.divertOptions,
                userSettings: (state) => state.System.userSettings,
                brandsActive: (state) => state.Brands.active,
            }),
            textColor() {
                return { color: `${getPresenceColor(this.loggedInUser.PresenceId)}`, 'font-size': '10px' };
            },
            roleBasedLogin() {
                return this.userSettings.cases.roleBasedLogin && this.userSettings.cases.roleBasedLogin.active;
            },
            useC1Diverts() {
                return this.userSettings.system.diverts.c1Diverts && this.userSettings.system.diverts.c1Diverts.active;
            },
        },
        watch: {
            timepickerOpen(newVal) {
                if (!newVal) {
                    this.currentDivert = null;
                }
            },
            menu: {
                immediate: true,
                async handler(newVal) {
                    if (!newVal) return;
                    if (!this.presence) {
                        this.$store.dispatch('CallDivert/getPresence');
                    }
                    if (this.storeDivertOptions.length === 0) {
                        try {
                            this.divLoading = true;
                            await this.$store.dispatch('CallDivert/divertOptions');
                            // only take 'speed' diverts
                            this.divLoading = false;
                        } catch (error) {
                            console.error('Error fetching divert options:', error);
                            this.divLoading = false;
                            this.showError = true;
                        }
                    }

                    if (this.storeDivertOptions.length > 0) {
                        this.divertOptions = this.storeDivertOptions.filter(
                            (el) =>
                                (el.durationSeconds > 0 && el.durationText !== null) ||
                                el.durationCode === 'ufn' ||
                                el.durationCode === 'nextday'
                        );
                        // aea wanted specifically lunch with editable time so we pick it
                        this.aeaCustom = this.storeDivertOptions
                            .filter((item) => item.durationSeconds === 0 && !item.durationCode)
                            .map((item) => {
                                return { ...item, durationText: this.$t('userMenu.chooseTime') };
                            });
                    }
                    this.getUserStatus();
                },
            },

            showNoti(newVal, oldVal) {
                if (oldVal == null || newVal == null) return;
                this.handleChangeNotiStatus(newVal);
            },
        },
        methods: {
            getPresenceColor,
            horseToast() {
                this.$toasted.show('Det är fredag idag!! :) ');
            },
            horseOnFridays() {
                const d = new Date();
                if (d.getDay() === 5) {
                    this.showHorse = true;
                }
            },

            async getUserStatus() {
                const payload = {
                    userId: this.loggedInUser.userId,
                    data: ['Notify'],
                };
                const result = await this.$store.dispatch('Users/getSpecificUserInfo', payload);
                this.showNoti = result[0].Notify;
            },
            async handleChangeNotiStatus(val) {
                if (val == null) return;
                const payload = {
                    userId: this.loggedInUser.userId,
                    data: { table: 'Notify', value: val },
                };
                await this.$store.dispatch('Users/updateUserPresence', payload);
                this.loggedInUser.Notify = val ? 1 : 0;
            },
            routeToProfileView() {
                this.$router.push({ name: 'Profile' });
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        width: 700px;
        background: white;
        border-radius: 20px;
        min-height: 400px;
    }

    .pa-4 {
        background: white;
    }
    .image-container {
        cursor: pointer;
    }

    .divert-container-error {
        display: flex;
        padding: 30px 0 30px 0;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }

    .buttons {
        display: flex;
        gap: 1em;

        button.profile-button {
            display: inline-flex;
            height: 32px;
            max-width: 96px;
            padding: var(--Spacing-8, 8px);
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 20px;
            border: 1px solid;
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "userMenu": {
            "brands": "Brands",
            "reference": "Reference",
            "logIn": "Log in",
            "showNotifications": "Show notifications",
            "chooseTime": "Choose time",
            "changePictureTooltip": "Click to change your profile picture",
            "divertError": "An error occurred while fetching divert options. Please try again later.",
            "profileButton": "Open profile"
        }
    },
    "sv": {
        "userMenu": {
            "brands": "Brands",
            "reference": "Hänvisning",
            "logIn": "Logga in",
            "showNotifications": "Visa notiser",
            "chooseTime": "Välj tid",
            "changePictureTooltip": "Klicka för att ändra din profilbild",
            "divertError": "Vi lyckades inte hämta hänsvisningar just nu. Försök igen senare.",
            "profileButton": "Öppna profil"
        }
    }
}
</i18n>
