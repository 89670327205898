import { ref, watch } from 'vue';
import type { Ref } from 'vue';

/**
 * Syncs a Vue ref with localStorage
 * Removes the key from localStorage if the value is an empty string
 */
export function useLocalStorage<T>(
    caseId: string | null,
    key: string,
    defaultValue: T,
    localStorage: Storage,
): { data: Ref<{ value: T }>; reset: () => void } {
    const storageKey = caseId ?? key;

    const storedValue = localStorage.getItem(storageKey);
    let storedData: T;

    if (caseId) {
        storedData = storedValue ? JSON.parse(storedValue) : {};
    } else {
        storedData = storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    }

    // @ts-ignore - data is initialized with storedData
    const data = ref(caseId ? (storedData[key] !== undefined ? storedData[key] : defaultValue) : storedData);

    watch(
        data,
        (newValue) => {
            if (caseId) {
                const currentData = JSON.parse(localStorage.getItem(caseId) || '{}');
                currentData[key] = newValue;
                localStorage.setItem(caseId, JSON.stringify(currentData));
            } else {
                localStorage.setItem(key, JSON.stringify(newValue));
            }
        },
        { deep: true },
    );

    const reset = () => {
        if (caseId) {
            const currentData = JSON.parse(localStorage.getItem(caseId) || '{}');
            delete currentData[key];
            localStorage.setItem(caseId, JSON.stringify(currentData));
        } else {
            localStorage.removeItem(key);
        }
        data.value = defaultValue;
    };

    return { data, reset };
}

/**
 * Extended version of useLocalStorage with sanitization of image src
 */
export function useLocalStorageContent<T>(
    caseId: string | null,
    key: string,
    defaultValue: T,
    shadowDom: {
        sanitizeImageSrc: (src: string) => string;
    },
    localStorage: Storage,
): { data: Ref<{ value: T }>; reset: () => void } {
    const { data, reset } = useLocalStorage<T>(caseId, key, defaultValue, localStorage);

    watch(
        data,
        (newValue) => {
            let sanitizedValue = newValue as unknown as string;
            if (typeof sanitizedValue === 'string') {
                sanitizedValue = shadowDom.sanitizeImageSrc(sanitizedValue);
            }

            if (caseId) {
                const currentData = JSON.parse(localStorage.getItem(caseId) || '{}');
                currentData[key] = sanitizedValue;
                localStorage.setItem(caseId, JSON.stringify(currentData));
            } else {
                localStorage.setItem(key, JSON.stringify(sanitizedValue));
            }
        },
        { deep: true },
    );

    return { data, reset };
}
