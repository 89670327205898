const MAX_ATTACHMENT_SIZE = 25000000; // 25 MB
import { v4 as uuidv4 } from 'uuid';
import { mimeTypes, imageMimeTypes, imageFileExtensions, fileTypes } from '@/enums/files.enums';

export function fileReaderHandler(files, { maxFileSize = MAX_ATTACHMENT_SIZE } = {}) {
    if (files.length > 0) {
        let fileSizeTotal = 0;
        const validFiles = [];
        for (const file of files) {
            if (file.size > maxFileSize) {
                // eslint-disable-next-line no-alert
                alert('Filen är för stor');
                files = [];
                throw new Error('File size too large');
            } else {
                validFiles.push(file);
                fileSizeTotal += file.size;
            }
        }
        if (fileSizeTotal > maxFileSize) {
            // eslint-disable-next-line no-alert
            alert('Filerna är för stora');
            throw new Error('File size too large');
        }

        const filePromises = validFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    try {
                        const response = formatFile(file, reader.result.split(',')[1]);
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.addEventListener('error', (error) => {
                    reject(error);
                });
                reader.readAsDataURL(file);
            });
        });
        return Promise.all(filePromises);
    }
    throw new Error('No files');
}

export function formatFile(file, contentBytes) {
    return {
        contentBytes: file.contentBytes || contentBytes,
        name: file.name,
        contentId: file.contentId || null,
        contentType: file.contentType || file.type,
        size: file.size,
        isInline: false,
        lastModifiedDateTime: file.lastModifiedDate,
        id: file.id || null,
    };
}

export function fileSizeFormatted(fileSize) {
    if (!fileSize) {
        return 0;
    }

    const decimalPoint = 2;
    if (fileSize == 0) {
        return '0 Bytes';
    }
    const k = 1000;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(fileSize) / Math.log(k));
    return Number.parseFloat((fileSize / k ** i).toFixed(dm)) + ' ' + sizes[i];
}

export function fileIconByContentType(contentType) {
    if (!contentType) {
        return 'mdi-file-outline';
    }
    const [type, subtype] = contentType.split('/'); // ex. image/png, application/pdf
    switch (true) {
        case type.includes('image'): {
            return 'mdi-file-image';
        }
        case type.includes('video'): {
            return 'mdi-video-outline';
        }
        case type.includes('audio'): {
            return 'mdi-audio';
        }
        case type === 'application' && subtype.includes('pdf'): {
            return 'mdi-file-pdf-box';
        }
        case subtype.includes('word'): {
            return 'mdi-file-word-outline';
        }
        case type === 'application' && subtype.includes('vnd.openxmlformats-officedocument.spread'): {
            return 'mdi-microsoft-excel';
        }
        case type === 'application' &&
            (subtype.includes('powerpoint') || (subtype.includes('presentation') && subtype.includes('office'))): {
            return 'mdi-microsoft-powerpoint';
        }
        case type === 'message': {
            return 'mdi-email-outline';
        }
        case type === 'application' && subtype.includes('zip'): {
            return 'mdi-folder-zip-outline';
        }
        case contentType.includes('text'): {
            return 'mdi-text';
        }
        default: {
            return 'mdi-file-outline';
        }
    }
}
export function downloadFile(data, type, name, format) {
    const linkSource = `data:${type};${format || 'base64'},${data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = name;

    downloadLink.click();
}

export function getURLFromFile(newFile, context) {
    const vm = context;
    return new Promise((resolve, reject) => {
        try {
            let objectUrl = '';
            const fileType = getDownloadedFileType(newFile);
            if (fileType === 'image') {
                const img = new Image();
                img.src = URL.createObjectURL(newFile);
                img.addEventListener('load', function () {
                    const widthHeightRel = this.width / this.height;
                    let imageForm = '';
                    if (this.width > this.height) {
                        imageForm = 'landscape';
                    } else if (this.width < this.height) {
                        imageForm = 'portrait';
                    }
                    let imgWidth = 595;
                    let imgHeight = 842;
                    if (imageForm === 'landscape') {
                        imgWidth = 842;
                        imgHeight = imgWidth / widthHeightRel;
                    }
                    if (imageForm === 'portrait') {
                        imgWidth = Math.round(imgHeight * widthHeightRel);
                        imgHeight = 842;
                    }
                    const eightyPercentOfWindowHeight = window.innerHeight * 0.8;
                    const eightyPercentOfWindowWidth = window.innerWidth * 0.8;
                    // if the image is bigger than 80% of the window height, set the image height to 80% of the window height
                    if (imgHeight > eightyPercentOfWindowHeight) {
                        const shrinkFactor = eightyPercentOfWindowHeight / imgHeight;
                        imgHeight = eightyPercentOfWindowHeight;
                        imgWidth *= shrinkFactor;
                    }
                    // do the same check for the width
                    if (imgWidth > eightyPercentOfWindowWidth) {
                        const shrinkFactor = eightyPercentOfWindowWidth / imgWidth;
                        imgWidth = eightyPercentOfWindowWidth;
                        imgHeight *= shrinkFactor;
                    }

                    vm.imageWidth = imgWidth;
                    vm.imageHeight = imgHeight;

                    // objectUrl = window.URL.createObjectURL(img);
                    const canvas = document.createElement('canvas');
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
                    const top = window.innerWidth / 2 - imgWidth / 2;
                    const left = window.innerHeight / 2 - imgHeight / 2;
                    vm.handleWidth({ top, left, width: imgWidth, height: imgHeight });
                    resolve(canvas.toDataURL(mimeTypes.PNG));
                });
            } else if (fileType === fileTypes.PDF) {
                vm.imageHeight = 0;
                vm.imageWidth = 0;
                objectUrl = window.URL.createObjectURL(newFile);
                resolve(objectUrl);
            }
        } catch (error) {
            console.error('error', error);
            reject(error);
        }
    });
}
/**
 * the purpose of this function is to make sure the filetype is still in a format that can be previewed
 * this is to avoid possible XSS attacks by changing the file type to something else, for example text/html
 * @param {File} newFile
 * @returns {string}
 * @throws {Error} 415
 */
function getDownloadedFileType(newFile) {
    const contentType = newFile.type;
    if (Object.values(imageMimeTypes).includes(contentType)) {
        return 'image';
    }
    if (contentType === mimeTypes.PDF) {
        return fileTypes.PDF;
    }
    throw new Error('415');
}

export function convertBase64ImagesToInline(htmlContent) {
    // take base64 data and convert it to an file object, exchange the src attribute and data-src attribute to an uuidv4 and name it as contentId in the file object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    const files = [];

    for (const imgElement of imgElements) {
        const src = imgElement.getAttribute('src');
        if (src && src.startsWith('data:image')) {
            const contentId = uuidv4();
            imgElement.dataset.src = `cid:${contentId}`;

            const contentType = src.split(';')[0].split(':')[1];
            files.push({
                contentBytes: src.split(',')[1],
                name: getImageFileNameByType(contentId, contentType),
                contentId,
                contentType,
                size: src.length,
                isInline: true,
            });
        }
    }
    return { content: doc.body.innerHTML, files };
}

/**
 * Retrieves the file extension based on the provided MIME type.
 *
 * @param {string} mimeType - The MIME type of the file.
 * @returns {string|null} - The corresponding file extension, or null if no matching file type found.
 */
export function getFileExtensionFromMimeType(mimeType) {
    // Find the file type key from imageMimeTypes object
    const fileTypeKey = Object.keys(imageMimeTypes).find((key) => imageMimeTypes[key] === mimeType);

    // If fileTypeKey is found, return the corresponding extension from imageFileExtensions
    if (fileTypeKey && imageFileExtensions[fileTypeKey]) {
        return imageFileExtensions[fileTypeKey];
    }

    // Default return null if no matching file type found
    return null;
}

/**
 * Returns the image file name based on the provided name and type.
 *
 * @param {string} name - The original file name.
 * @param {string} type - The MIME type of the file.
 * @returns {string} - The modified file name.
 */
export function getImageFileNameByType(name, type) {
    const dividedType = name.split('.');

    const extension = '.' + dividedType.at(-1);
    if (Object.values(imageFileExtensions).includes(extension)) {
        return name;
    }

    const fileExtension = getFileExtensionFromMimeType(type);

    if (fileExtension) {
        return `${name}${fileExtension}`;
    }

    return name;
}

/**
 * Converts a base64 string to a data URI by adding the MIME type to the base64 string.
 *
 * @param {string} base64String - The base64 string to be converted.
 * @param {string} mimeType - The MIME type of the data, e.g. 'image/jpeg'.
 * @returns {string} The converted base64 string as a data URI, e.g. 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDA...'.
 */
export function base64ToDataUri(base64String, mimeType) {
    return `data:${mimeType};base64,${base64String}`;
}

/**
 *
 * @param {string} contentType
 * @returns a material design icon based on the given contentType
 */
export function getIconForContentType(contentType) {
    const contentTypeToIconMap = {
        // Images
        'image/jpeg': 'mdi-file-image',
        'image/png': 'mdi-file-image',
        'image/gif': 'mdi-file-image',
        'image/bmp': 'mdi-file-image',
        'image/webp': 'mdi-file-image',
        'image/tiff': 'mdi-file-image',

        // Documents
        'application/pdf': 'mdi-file-pdf-box',
        'application/msword': 'mdi-file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'mdi-file-word',
        'application/vnd.oasis.opendocument.text': 'mdi-file-document',

        // Spreadsheets
        'application/vnd.ms-excel': 'mdi-file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'mdi-file-excel',
        'application/vnd.oasis.opendocument.spreadsheet': 'mdi-file-excel',

        // Presentations
        'application/vnd.ms-powerpoint': 'mdi-file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'mdi-file-powerpoint',
        'application/vnd.oasis.opendocument.presentation': 'mdi-file-powerpoint',

        // Audio
        'audio/mpeg': 'mdi-file-music',
        'audio/ogg': 'mdi-file-music',
        'audio/wav': 'mdi-file-music',
        'audio/webm': 'mdi-file-music',

        // Video
        'video/mp4': 'mdi-file-video',
        'video/ogg': 'mdi-file-video',
        'video/webm': 'mdi-file-video',

        // Archives
        'application/zip': 'mdi-archive',
        'application/x-rar-compressed': 'mdi-archive',
        'application/x-7z-compressed': 'mdi-archive',
        'application/x-tar': 'mdi-archive',
        'application/gzip': 'mdi-archive',

        // Text
        'text/plain': 'mdi-file-document',
        'text/html': 'mdi-file-code',
        'application/json': 'mdi-file-code',
        'text/css': 'mdi-file-code',
        'text/javascript': 'mdi-file-code',

        // Default for unknown types
        default: 'mdi-file',
    };

    return contentTypeToIconMap[contentType] || contentTypeToIconMap.default;
}

/**
 *
 * @param {object} attachment
 */
export function downloadFromContentBytes(contentBytes, name) {
    const url = contentBytes;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Validates a file upload based on the provided rules.
 * @param {Object} file
 * @param {Number} maxSize
 * @param {Array} forbiddenFileTypes
 * @returns {Array} An array of objects representing the failed validation rules
 */
export function validateFileUpload(file, maxSize, forbiddenFileTypes) {
    const rules = [
        {
            key: 'maxFileSize',
            validate: (file) => file.size <= maxSize,
            details: { fileName: file.name, maxFileSize: (maxSize / (1024 * 1024)).toFixed(2) }, // Convert size to MB
        },
        {
            key: 'fileType',
            validate: (file) => {
                const fileExtension = file.name.split('.').pop();
                return !forbiddenFileTypes.includes(fileExtension);
            },
            details: { fileType: file.name.split('.').pop() },
        },
    ];

    return rules
        .filter((rule) => !rule.validate(file))
        .map((rule) => ({
            key: rule.key,
            ...rule.details,
        }));
}
