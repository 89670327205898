<template>
    <div>
        <!-- The divert select, sets divert on user from here, unless using custom duration dialog -->
        <DivertSelect
            @showCustomDuration="openCustomDurationDialog"
            v-model="divertSelectValue"
            durationButton
            :loading="userDivertLoading"
            class="user-menu-divert-select"
        />

        <!-- This dialog sets the divert on user on its own-->
        <CustomDurationDialog
            v-if="showCustomDurationDialog"
            v-model="showCustomDurationDialog"
            :userId="userId"
            @divert="handleCustomDurationDivert"
        />
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import * as usersV2Api from '@/api/users/users.v2';

    export default defineComponent({
        components: {
            CustomDurationDialog: () => import('@/components/Users/UserMenu/Divert/CustomDurationDialog.vue'),
            DivertSelect: () => import('@/components/Users/UserMenu/Divert/DivertSelect.vue'),
        },
        props: {
            userId: {
                type: Number,
                required: true,
            },
            refresh: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showCustomDurationDialog: false as boolean,
                currentDivertId: 1 as number,
                userDivertLoading: false,
            };
        },
        computed: {
            divertSelectValue: {
                get(): number {
                    return this.currentDivertId;
                },
                set(divertId: number) {
                    /** If same value as before, do nothing */
                    if (divertId === this.currentDivertId) return;

                    /** Use api to set */
                    this.setDivertOnUser(divertId);
                },
            },
        },
        watch: {
            refresh(newVal) {
                /** Fetch user divert again */
                if (newVal) {
                    this.getDivertOnUser(this.userId);
                }
            },
        },
        created() {
            this.init();
        },
        methods: {
            async init() {
                this.getDivertOnUser(this.userId);
            },
            async getDivertOnUser(userId: number) {
                this.userDivertLoading = true;
                /** Fetch logged in user's current divert */
                const usersDivert = await usersV2Api.getDivertOnUser(userId);

                if (usersDivert) {
                    this.currentDivertId = usersDivert;
                }
                this.userDivertLoading = false;
            },
            async setDivertOnUser(divertId: number) {
                this.userDivertLoading = true;
                const res = await usersV2Api.setDivertOnUser(this.userId, { divert: divertId });

                if (res) {
                    this.currentDivertId = divertId;
                }
                this.userDivertLoading = false;
            },
            handleCustomDurationDivert(divertId: number) {
                this.currentDivertId = divertId;
            },
            openCustomDurationDialog() {
                this.showCustomDurationDialog = true;
            },
        },
    });
</script>
<style scoped>
    .user-menu-divert-select {
        min-width: 160px;
        max-width: 200px;
    }
</style>
