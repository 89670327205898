import axios, { type AxiosResponse } from 'axios';
import type {
    CommentPayload,
    CreateCommentsData,
    CaseData,
    TemplateResponse,
    AttachmentObject,
    UploadAttachment,
    SendCommentResponse,
} from '@/types/createComments.state';

export function sendComment(data: CommentPayload): Promise<AxiosResponse<SendCommentResponse>> {
    return axios.post<SendCommentResponse>('/v3/comments', data);
}

export function uploadAttachment(files: UploadAttachment[]): Promise<AxiosResponse<AttachmentObject[]>> {
    return axios.post<AttachmentObject[]>('/attachments', files);
}

export function getCreateCommentsData(caseId: string): Promise<AxiosResponse<CreateCommentsData>> {
    return axios.get<CreateCommentsData>(`/comments/${caseId}/write`);
}

export function getCaseData(caseId: string): Promise<AxiosResponse<CaseData>> {
    return axios.get<CaseData>('/comments', {
        params: {
            caseId,
            itemsPerPage: 100,
            showSystemNotifications: false,
        },
    });
}

export function getSignatureTemplate(id: number): Promise<AxiosResponse<TemplateResponse>> {
    return axios.get<TemplateResponse>(`/v2/templates/${id}`);
}

export function getSubCasesWithOpenInProgress(caseId: string): Promise<AxiosResponse<{ subcases: number }>> {
    return axios.get(`/comments/${caseId}/subcases`);
}

export function updateCase(data: Partial<CaseData> & { closeSubCases: boolean; changed: object }): Promise<
    AxiosResponse<{
        status: number;
        message: string;
    }>
> {
    let query = `/cases/${data.caseId}`;

    if (data.closeSubCases) {
        query += '?closeSubCases=true';
    }
    return axios.patch<{
        status: number;
        message: string;
    }>(query, data.changed);
}

export function getThreadSize(commentIds: number[]): Promise<AxiosResponse<{ threadSize: number }>> {
    return axios.get<{ threadSize: number }>('/comments/threadsize', {
        params: {
            attachedComments: commentIds,
        },
    });
}

export function getFilesSize(ids: number[]): Promise<AxiosResponse<{ fileSize: number }>> {
    return axios.get<{ fileSize: number }>('/attachments/size', {
        params: {
            ids,
        },
    });
}
