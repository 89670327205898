import axios from 'axios';

// TAPI START

function getAllQueues() {
    return axios.get('tapi/groups');
}

function getStatistics(q) {
    return axios.get(`tapi/groups/${q}/info`);
}
function loginLogoutToQueue(data) {
    return axios.put(`tapi/users/${data.userid}/groups`, {
        groups: data.groups,
    });
}

// TAPI END

function getUserDivertSettings(userId) {
    return axios.get(`/queues/divert-settings/${userId}`);
}

function getQueueActiveChannels(queueId) {
    return axios.get(`/queues/${queueId}/channels-active`);
}

function saveUserDivertSettings(payload) {
    return axios.post(`/queues/divert-settings/${payload.userId}`, payload);
}

function getQueueCallbackSlotTime(queueId) {
    return axios.get(`/queues/callback/slot-time/${queueId}`);
}

function getQueueChannelSurveys(data) {
    return axios.get(`/queues/${data.queueId}/survey`, {
        params: {
            channelType: data.channelType,
        },
    });
}

function saveQueueChannelSurveys(data) {
    return axios.post('/queues/survey', data);
}
function answerCall(payload) {
    return axios.post('/queues/answer', payload);
}

function getNextCase() {
    return axios.get('/queues/next/case');
}

export default {
    getAllQueues,
    getStatistics,
    loginLogoutToQueue,
    getUserDivertSettings,
    saveUserDivertSettings,
    getQueueCallbackSlotTime,
    getQueueChannelSurveys,
    saveQueueChannelSurveys,
    answerCall,
    getNextCase,
    getQueueActiveChannels,
};
