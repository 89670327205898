import api from '@/api/administration/users';
import { userCreatorStepsTemplate, creatorUserObjectTemplate } from '@/templates/admin.template';
import pbx from '@/api/pbx/pbx';
import { httpStatusCode } from '@/enums/response.enums';
import i18n from '../../../i18n';

import noUserImageData from '@/assets/General/no_user.png?base64';
const noUserImage = `data:image/png;base64,${noUserImageData}`;

const state = {
    loaders: {
        search: false,
        savingUser: false,
        getUser: false,
        usersList: false,
        internalNumber: false,
        publicNumber: false,
    },
    creatorUserObject: structuredClone(creatorUserObjectTemplate),
    creatorFormValidity: {
        login: false,
        profile: true,
        connection: false,
        role: false,
        group: true,
        template: true,
    },
    creatorModalInfo: {
        open: false,
        type: 'creator',
        user: 0,
        currentStep: 1,
        swyxEnabled: false,
    },
    search: {
        value: '',
        page: 1,
        roleIdFilter: [],
    },
    roles: [],
    groups: [],
    userRoleList: [],
    creatorKey: 0,
    userAdminKey: 0,
    swyxCreationState: false,

    snoozeOptions: null,
    userCreatorSteps: [],
    beforeEditUserData: {},
    suggestedPublicNumbers: [],
    credentialModal: false,
    selectedUser: {},
    showPasswordInformation: false,
    users: [],
    swyxSystemSettings: {},

    userList: [],
};
// Getter functions
const getters = {
    getCreatorValuesShownInSummary(state) {
        const asArray = Object.entries(state.creatorUserObject);
        const filtered = asArray.filter(([, value]) => {
            return typeof value === 'object' && value.show;
        });
        return Object.fromEntries(filtered);
    },
    creatorTotalSteps(state) {
        return state.userCreatorSteps.length;
    },
    creatorOnFirstStep(state) {
        return Number.parseInt(state.creatorModalInfo.currentStep) === 1;
    },
    creatorOnLastStep(state, getters) {
        return Number.parseInt(state.creatorModalInfo.currentStep) === getters.creatorTotalSteps;
    },
    creatorOpen(state) {
        return state.creatorModalInfo.open;
    },
};
// Actions
const actions = {
    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // ---------------FETCHING FROM API---------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    async saveRoleChange({ commit, dispatch }, payload) {
        try {
            const res = await api.saveRoleChange(payload);
            const index = state.users.findIndex((user) => user.UserID === payload.userId);
            if (index === -1) {
                this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                    icon: 'mdi-cancel',
                    type: 'error',
                });
            }

            commit('UPDATE_USER_ROLE', { index, payload });
            let roleExistsInFilter = false;
            for (const role of state.users[index].Roles) {
                if (state.search.roleIdFilter.includes(role.id) && role.active) {
                    roleExistsInFilter = true;
                }
            }
            if (!roleExistsInFilter) {
                await dispatch('removeUserFromList', index);
            }
            this._vm.$toasted.show(i18n.t('adminStore.changedRole', { name: payload.name }), {
                icon: 'mdi-content-save',
                type: 'success',
            });

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },

    removeUserFromList({ state, commit }, index) {
        const users = structuredClone(state.users);
        users.splice(index, 1);
        commit('SET_USERS', users);
    },

    setUsers({ commit }, users) {
        commit('SET_USERS', users);
    },

    async getRoles(_, payload) {
        try {
            const res = await api.getRoles(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async addUser({ dispatch, commit }, payload) {
        try {
            dispatch('setLoadersKey', { savingUser: true });
            const res = await api.addUser(payload);

            this._vm.$toasted.show(i18n.t(res.data.message), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            commit('INCREMENT_CREATOR_KEY');

            return res;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });
            dispatch('setLoadersKey', { savingUser: false });

            throw error;
        }
    },
    async deleteUser({ state, commit }, userId) {
        try {
            const res = await api.deleteUser(userId);

            // Return to avoid deleting a user from our local state of the users list
            if (res.status !== httpStatusCode.OK) return null;

            const users = state.userList.filter((user) => user.UserID !== userId);
            commit('SET_USER_LIST', users);

            this._vm.$toasted.show(i18n.t('usersStore.userDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });

            return res;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },

    async checkSwyxInternalNumberValidation({ dispatch }, payload) {
        try {
            dispatch('setLoadersKey', { publicNumber: true });
            const res = await api.checkSwyxInternalNumberValidation(payload);
            dispatch('setLoadersKey', { publicNumber: false });
            return res.data;
        } catch (error) {
            dispatch('setLoadersKey', { publicNumber: false });
            return error.response.data;
        }
    },

    async getAssignedInternalNumbers(_, payload) {
        try {
            const res = await api.getAssignedInternalNumbers(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getPublicNumberSuggestions({ dispatch, state }, payload) {
        try {
            if (payload === state.beforeEditUserData?.internalNumber) return;

            dispatch('setLoadersKey', { publicNumber: true });

            let res = null;
            const useSwyx = isSwyxCompatibilityActive();
            if (!useSwyx) {
                res = await pbx.getPublicNumberSuggestions();
            } else {
                res = await api.getPublicNumberSuggestions(payload);
            }
            dispatch('setSuggestedPublicNumbers', res.data);

            dispatch('setLoadersKey', { publicNumber: false });
        } catch (error) {
            dispatch('setLoadersKey', { publicNumber: false });
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getSwyxTrunks(_, payload) {
        try {
            const res = await api.getSwyxTrunks(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },

    async getUserData(_, payload) {
        try {
            const res = await api.getUserDataToEdit(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },

    async getSwyxUsers(_, payload) {
        try {
            const res = await api.getSwyxUsers(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async checkEmailValidation(_, payload) {
        try {
            const res = await api.checkEmailValidation(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getSwyxSystemSettings({ commit }, payload) {
        try {
            const res = await api.getSwyxSystemSettings(payload);
            commit('SET_SWYX_SYSTEM_SETTINGS', res.data);
            return res.data;
        } catch (error) {
            console.error('Error fetching swyx system settings: ', error.response.data);
        }
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // ---------------- VALIDATION -------------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    internalNumberValidation({ dispatch, state }, payload) {
        if (payload === state.beforeEditUserData?.internalNumber) {
            return 'OK';
        }
        const useSwyx = isSwyxCompatibilityActive();
        if (!useSwyx) {
            return dispatch('checkInternalNumberValidation', payload);
        }
        return dispatch('checkSwyxInternalNumberValidation', payload);
    },
    publicNumberValidation({ dispatch, state }, payload) {
        if (payload === state.beforeEditUserData?.publicNumber) {
            return true;
        }
        const useSwyx = isSwyxCompatibilityActive();
        if (!useSwyx) {
            return dispatch('checkPublicNumberValidation', payload);
        }
        return dispatch('checkSwyxPublicNumberValidation', payload);
    },
    async checkInternalNumberValidation({ dispatch }, payload) {
        dispatch('setLoadersKey', { publicNumber: true });
        const res = await pbx.checkInternalNumberValidation({ number: payload });
        dispatch('setLoadersKey', { publicNumber: false });
        return res.data ? 'OK' : 'NotFree';
    },
    async checkPublicNumberValidation(_, payload) {
        const { data } = await pbx.checkPublicNumberValidation({ number: payload });
        return {
            IsFree: data.isFree || false,
            HasCorrectFormat: data.hasCorrectFormat || false,
            IsAllowed: data.isAllowed || false,
        };
    },
    emailValidation({ dispatch, state }, payload) {
        if (payload === state.beforeEditUserData?.email) {
            return true;
        }
        return dispatch('checkEmailValidation', payload);
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // ------------ PAGINATION & SEARCH --------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    async getUsersPaginated({ dispatch, commit, state }, { _, page }) {
        try {
            const roles = state.search.roleIdFilter;

            const users = await dispatch(
                'Users/getUsersAndRoles',
                {
                    page,
                    search: state.search.value,
                    roleIdFilter: roles,
                },
                { root: true },
            );

            if (page === 1) {
                commit('SET_USER_LIST', users.data);
            } else {
                commit('SET_USER_LIST', state.userList.concat(users.data));
            }

            return users;
        } catch (error) {
            console.error('Error fetching users', error);
        }
    },

    fetchUsers({ state, dispatch }, page = 1) {
        const roles = state.search.roleIdFilter;
        if (!roles.length) {
            return { data: [], hasMore: false };
        }
        return dispatch(
            'Users/getUsersAndRoles',
            {
                page,
                search: state.search.value,
                roleIdFilter: roles,
            },
            { root: true },
        );
    },
    async setRoleIdFilter({ dispatch }, payload) {
        await dispatch('setSearchKey', { roleIdFilter: payload });
        dispatch('setPage', 1);
        dispatch('getUsers');
    },

    setPage({ dispatch }, payload) {
        dispatch('setSearchKey', { page: payload });
    },

    setUserModalNextStep({ dispatch, state, getters }) {
        let nextStep = 0;
        if (state.creatorModalInfo.currentStep < getters.creatorTotalSteps) {
            nextStep = state.creatorModalInfo.currentStep + 1;
        } else {
            nextStep = 1;
        }
        dispatch('setCreatorModalInfoKeys', [{ currentStep: nextStep }]);
    },

    setUserModalPreviousStep({ dispatch, state }) {
        if (state.creatorModalInfo.currentStep === 0) return;
        const previousStep = state.creatorModalInfo.currentStep - 1;
        dispatch('setCreatorModalInfoKeys', [{ currentStep: previousStep }]);
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // ------------------ CONNECTION -----------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    async checkSwyxPublicNumberValidation(_, payload) {
        try {
            const res = await api.checkSwyxPublicNumberValidation(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getNextInternalNumber({ dispatch }, payload) {
        try {
            let res = null;
            dispatch('setLoadersKey', { internalNumber: true });
            const useSwyx = isSwyxCompatibilityActive();
            if (!useSwyx) {
                res = await pbx.getNextInternalNumber(payload);
            } else {
                res = await api.getNextInternalNumber(payload);
            }
            dispatch('setLoadersKey', { internalNumber: false });
            return res.data;
        } catch (error) {
            dispatch('setLoadersKey', { internalNumber: false });
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // -------------------- LOGIC --------------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    async userAdminInit({ dispatch, commit }) {
        const roles = await dispatch('getRoles');
        commit('SET_ROLES', roles);
        await dispatch(
            'setRoleIdFilter',
            roles.map((role) => role.ID),
        );
    },
    async getUsers({ dispatch, commit, state }) {
        dispatch('setLoadersKey', { usersList: true });
        const minLoadingTime = 400; // ms
        const before = performance.now();
        const { data } = await dispatch('fetchUsers', state.search.page);
        commit('SET_USERS', data);
        const timeTaken = performance.now() - before;
        if (timeTaken < minLoadingTime) {
            const timeout = minLoadingTime - timeTaken;
            await new Promise((resolve) => setTimeout(resolve, timeout));
        }
        dispatch('setLoadersKey', { usersList: false });
    },
    async getUserDataToEdit({ dispatch }) {
        dispatch('setLoadersKey', { getUser: true });

        const userData = await dispatch('getUserData', state.creatorModalInfo.user);
        await dispatch('setUserDataToEdit', userData);

        dispatch('setLoadersKey', { getUser: false });
    },

    async setUserDataToEdit({ state, commit, dispatch }, payload) {
        await commit('SET_BEFORE_EDIT_USER_DATA', payload);
        if (payload.publicNumber) {
            await dispatch('setSuggestedPublicNumbers', [payload.publicNumber]);
        }

        const template = structuredClone(creatorUserObjectTemplate);
        const keys = Object.keys(payload);
        for (const key of keys) {
            template[key].value = payload[key];
        }

        if (state.creatorModalInfo.type === 'editor') {
            delete template.template;
        }

        if (!template.image.value) {
            template.image.value = noUserImage;
        }

        await commit('SET_USER_CREATION_OBJECT', template);
        dispatch('setSwyxEnabled', payload.swyx);
    },

    async prepareAddUser({ commit, dispatch, state }) {
        const payload = {
            type: state.creatorModalInfo.type,
            userId: state.selectedUser.UserID,
            oldSwyxCreationState: state.beforeEditUserData.swyx || false,
            swyxCreationState: state.creatorModalInfo.swyxEnabled,
            login: state.creatorUserObject?.userName?.value || '',
            password: state.creatorUserObject?.password?.value || '',
            image: state.creatorUserObject?.image?.value || '',
            title: state.creatorUserObject?.title?.value || '',
            description: state.creatorUserObject?.description?.value || '',
            name: state.creatorUserObject?.displayName?.value || '',
            email: state.creatorUserObject?.email?.value || '',
            internalNumber: state.creatorUserObject?.internalNumber?.value || '',
            publicNumber: state.creatorUserObject?.publicNumber?.value || '',
            roles: state.creatorUserObject?.roles?.value || '',
            groups: state.creatorUserObject?.groups?.value.map((group) => group.id) || [],
            templateUserId: state.creatorUserObject?.template?.value?.id || 0,
            credentialChange: state.creatorUserObject?.credentialChange?.value || false,
        };
        await dispatch('addUser', payload);
        dispatch('closeCreatorModal');
        commit('INCREMENT_USER_ADMIN_KEY');
    },

    closeCreatorModal({ commit, dispatch }) {
        commit('SET_CREATOR_MODAL_INFO', { open: false, type: 'creator', user: 0, currentStep: 1, swyxEnabled: false });
        commit('SET_SELECTED_USER', {});
        commit('SET_USER_CREATION_OBJECT', structuredClone(creatorUserObjectTemplate));
        commit('SET_BEFORE_EDIT_USER_DATA', {});
        dispatch('setSuggestedPublicNumbers', []);
        dispatch('incrementCreatorKey');
        dispatch('setLoadersKey', { savingUser: false });
        commit('SET_SHOW_PASSWORD_INFORMATION', false);
    },

    setCreatorModalInfoKeys({ commit }, payload) {
        for (const updateKey of payload) {
            const [key, value] = Object.entries(updateKey)[0];
            commit('SET_CREATOR_MODAL_INFO_KEY', { key, value });
        }
    },
    setSwyxCreationState({ commit, dispatch, state }, data) {
        commit('SET_SHOW_PASSWORD_INFORMATION', false);
        if (state.creatorModalInfo.type === 'creator' && data) {
            dispatch('setAllUserCreatorSteps');
        } else {
            dispatch('setDefaultUserCreatorSteps');
            if (data && !state.beforeEditUserData.swyx) {
                setTimeout(() => {
                    commit('SET_SHOW_PASSWORD_INFORMATION', true);
                }, 1000);
            }
            commit('SET_SHOW_PASSWORD_INFORMATION', false);
        }
        commit('SET_SWYX_CREATION_STATE', data);
    },
    setSwyxEnabled({ dispatch, commit }, payload) {
        dispatch('setCreatorModalInfoKeys', [{ swyxEnabled: payload }]);

        if (state.creatorModalInfo.type === 'creator' && payload) {
            dispatch('setAllUserCreatorSteps');
        } else {
            dispatch('setDefaultUserCreatorSteps');
            if (payload && !state.beforeEditUserData.swyx) {
                setTimeout(() => {
                    commit('SET_SHOW_PASSWORD_INFORMATION', true);
                }, 1000);
            }
            commit('SET_SHOW_PASSWORD_INFORMATION', false);
        }
    },
    openModalCreator({ dispatch }) {
        dispatch('setCreatorModalInfoKeys', [{ open: true }, { type: 'creator' }, { user: state.selectedUser.UserID }]);
        dispatch('setDefaultUserCreatorSteps');
    },
    async openModalEdit({ dispatch, state }) {
        dispatch('setCreatorModalInfoKeys', [{ open: true }, { type: 'editor' }, { user: state.selectedUser.UserID }]);
        await dispatch('setDefaultUserCreatorSteps');
        await dispatch('getUserDataToEdit', state.creatorModalInfo.user);
    },

    setGroupsAlreadyJoined({ dispatch }, groups) {
        const groupsAlreadyJoined = [];
        for (const group of groups) {
            if (group.UsersInGroup.some((user) => user.UserId === state.selectedUser.UserID)) {
                groupsAlreadyJoined.push({ id: group.ID, name: group.Name });
            }
        }
        dispatch('setCreatorUserObjectKey', { groups: groupsAlreadyJoined });
    },

    handleSelectedGroupChange({ commit }, group) {
        if (state.creatorUserObject.groups.value.some((g) => g.id === group.ID)) {
            commit('REMOVE_GROUP_FROM_SELECTED_GROUPS', group.ID);
        } else {
            commit('ADD_GROUP_TO_SELECTED_GROUPS', { id: group.ID, name: group.Name });
        }
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // --------------- SIMPLE SETTERS ----------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    setSuggestedPublicNumbers({ commit }, payload) {
        commit('SET_SUGGESTED_PUBLIC_NUMBERS', payload);
    },
    setCredentialModal({ commit }, payload) {
        commit('SET_CREDENTIAL_MODAL', payload);
    },
    setSelectedUser({ commit }, payload) {
        commit('SET_SELECTED_USER', payload);
    },
    setUserModalValidity({ commit }, payload) {
        commit('SET_USER_MODAL_VALIDITY', payload);
    },
    incrementCreatorKey({ commit }) {
        commit('INCREMENT_CREATOR_KEY');
    },
    setDefaultUserCreatorSteps({ commit }) {
        const steps = userCreatorStepsTemplate.filter((step) => !step.onlySwyx);
        commit('SET_USER_CREATOR_STEPS', steps);
    },
    setAllUserCreatorSteps({ commit }) {
        commit('SET_USER_CREATOR_STEPS', userCreatorStepsTemplate);
    },
    setCreatorUserObjectKey({ commit }, payload) {
        const [key, value] = Object.entries(payload)[0];
        commit('SET_CREATOR_USER_OBJECT_KEY', { key, value });
    },
    setLoadersKey({ commit }, payload) {
        const [key, value] = Object.entries(payload)[0];
        commit('SET_LOADERS_KEY', { key, value });
    },
    setSearchKey({ commit }, payload) {
        const [key, value] = Object.entries(payload)[0];
        commit('SET_SEARCH_KEY', { key, value });
    },
};
// Mutations
const mutations = {
    SET_USER_LIST(state, data) {
        state.userList = data;
    },

    SET_SELECTED_USER(state, data) {
        state.selectedUser = data;
    },
    SET_CREATOR_MODAL_INFO(state, data) {
        state.creatorModalInfo = data;
    },
    SET_SWYX_CREATION_STATE(state, data) {
        state.swyxCreationState = data;
    },
    SET_USER_CREATION_OBJECT(state, data) {
        state.creatorUserObject = data;
    },
    SET_CREATOR_USER_OBJECT_KEY(state, data) {
        const { key, value } = data;
        state.creatorUserObject[key].value = value;
    },
    SET_CREATOR_MODAL_INFO_KEY(state, data) {
        const { key, value } = data;
        state.creatorModalInfo[key] = value;
    },

    SET_USER_MODAL_VALIDITY(state, data) {
        const [key, value] = Object.entries(data)[0];
        state.creatorFormValidity[key] = value;
    },
    INCREMENT_CREATOR_KEY(state) {
        state.creatorKey++;
    },
    SET_USER_MODAL_CURRENT_STEP(state, data) {
        state.currentStep = data;
    },

    SET_USER_CREATOR_STEPS(state, data) {
        state.userCreatorSteps = data;
    },
    SET_BEFORE_EDIT_USER_DATA(state, data) {
        state.beforeEditUserData = data;
    },
    SET_SUGGESTED_PUBLIC_NUMBERS(state, data) {
        state.suggestedPublicNumbers = data;
    },
    SET_SHOW_PASSWORD_INFORMATION(state, data) {
        state.showPasswordInformation = data;
    },
    SET_ROLES(state, data) {
        state.roles = data;
    },
    SET_GROUPS(state, data) {
        state.groups = data;
    },
    SET_LOADERS_KEY(state, data) {
        const { key, value } = data;
        state.loaders[key] = value;
    },
    SET_SEARCH_KEY(state, data) {
        const { key, value } = data;
        state.search[key] = value;
    },
    SET_USERS(state, data) {
        state.users = data;
    },
    SET_CREDENTIAL_MODAL(state, data) {
        state.credentialModal = data;
    },
    INCREMENT_USER_ADMIN_KEY(state) {
        state.userAdminKey++;
    },
    SET_SWYX_SYSTEM_SETTINGS(state, data) {
        state.swyxSystemSettings = data;
    },
    REMOVE_GROUP_FROM_SELECTED_GROUPS(state, groupId) {
        state.creatorUserObject.groups.value = state.creatorUserObject.groups.value.filter((g) => g.id !== groupId);
    },
    ADD_GROUP_TO_SELECTED_GROUPS(state, group) {
        state.creatorUserObject.groups.value.push(group);
    },
    UPDATE_USER_ROLE(state, { index, payload }) {
        state.userList[index].Roles[payload.roleId - 1].active = payload.roleValue;
    },
    REMOVE_USER_FROM_LIST(state, payload) {
        state.users = payload;
    },
};

// This function is not currently used, but it will be used in the future when the telephony factory is implemented
function isSwyxCompatibilityActive() {
    return true;
}
// async function isSwyxCompatibilityActive(dispatch) {
// Checks if FREESWITCH is active

// const res = await dispatch('Integrations/getActiveStates', null, { root: true });
// if (!res) {
//     return true;
// }
// if (!res.FREESWITCH) {
//     return true;
// }
// Checks if its running in swyx compatibility mode
// const integrationData = await dispatch('Integrations/getSystemIntegrationData', pbxIntegrationId, { root: true });
// if (!integrationData || !integrationData.variables || !integrationData.variables.swyxCompatibilityMode) {
//     return true;
// }
// const { values } = integrationData.variables.swyxCompatibilityMode;
// if (!values || values.length === 0) {
//     return true;
// }
// return values[0] === '1';
// }

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
