<template>
    <section :class="paddingContainer">
        <section class="timeline-item-outer-container" :class="timelineItemContainerClass">
            <TimelineItemSubCases
                :item="item"
                :isExpanded="expanded"
                @expand="expand"
                @click="goToCase({ caseId: item.caseId })"
            />
            <section v-if="expanded" class="timeline-item-subcases">
                <TimelineItemSubCases
                    v-for="subcase in subcases"
                    :key="subcase.caseId"
                    :item="subcase"
                    :isExpanded="expanded"
                    @click="goToCase({ caseId: subcase.caseId })"
                />
            </section>
        </section>
    </section>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import TimelineItemSubCases from '@/components/Cases/TimelineItemSubCases.vue';

    interface Item {
        topic: string;
        dateTime: string;
        unreadMessages: number;
        internalType: string;
        internalSubType: string;
        source: string;
        caseId: string;
        subCasesCount: number;
        isChild: boolean;
        allowed: boolean;
    }

    export default defineComponent({
        name: 'TimelineItemSubCasesContainer',

        components: {
            TimelineItemSubCases,
        },

        props: {
            item: {
                type: Object as PropType<Item>,
                required: true,
            },
            subcases: {
                type: Array as PropType<Item[]>,
                required: true,
            },
        },

        data() {
            return {
                expanded: false,
            };
        },

        watch: {
            $route() {
                this.expanded = this.isSelectedParentCaseOrSubCase;
            },
        },
        mounted() {
            this.expanded = this.isSelectedParentCaseOrSubCase;
        },

        computed: {
            isSelectedParentCaseOrSubCase(): boolean {
                return this.subcases.some((subcase) => subcase.caseId === this.$route.params.caseId);
            },

            selectedCase(): Item {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Cases.selectedCase?.case as Case;
            },

            timelineItemContainerClass() {
                let classes = '';
                if (this.isSelectedCase() && this.expanded) {
                    classes += 'selected';
                }
                return this.expanded ? 'expanded ' + classes : classes;
            },

            paddingContainer() {
                return this.expanded
                    ? 'timeline-item-padding-container-expanded'
                    : 'timeline-item-padding-container-collapsed';
            },
        },

        methods: {
            goToCase({ caseId }: { caseId: string }) {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                this.$store.dispatch('Cases/goToCase', { caseId });
            },

            expand() {
                this.expanded = !this.expanded;
            },

            isSelectedCase() {
                return this.selectedCase?.caseId === this.item.caseId || this.$route.params.caseId === this.item.caseId;
            },
        },
    });
</script>

<style scoped>
    .timeline-item-padding-container-expanded {
        padding: 4px;
    }

    .timeline-item-padding-container-collapsed {
        padding: 0px 4px;
        margin-block: 3px;
    }

    .timeline-item-outer-container {
        display: flex;
        flex-direction: column;
        padding: 0px 4px;
        border-radius: 12px;
        gap: 2px;
    }

    .expanded {
        padding: 4px;
        background-color: var(--v-gray3-base);
    }

    .selected {
        background-color: var(--v-gray3-base);
    }

    .timeline-item-subcases {
        gap: 2px;
        display: flex;
        flex-direction: column;
    }
</style>
