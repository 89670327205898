<template>
    <Dialog
        id="widget-channel-relation-modal"
        :value="value"
        header
        headerDivider
        :returnButton="currentStep !== IdentifierModalStep.MODE_SELECT"
        footerDivider
        footer
        @input="closeModal"
        @return="currentStep = IdentifierModalStep.MODE_SELECT"
    >
        <template #header-title>{{ headerTitle }}</template>

        <!-- Content-->

        <section id="content">
            <ModeSelect v-if="currentStep === IdentifierModalStep.MODE_SELECT" v-model="tempSelectedMode" />
            <SingleSelect v-else-if="currentStep === IdentifierModalStep.SINGLE_SELECT" v-model="identifiers" />
            <MultipleSelect v-else-if="currentStep === IdentifierModalStep.MULTIPLE_SELECT" v-model="identifiers" />
        </section>

        <!-- Footer/Action buttons -->

        <template #action-buttons>
            <!-- Cancel / Close modal -->
            <standard-button @click="closeModal">Avbryt</standard-button>

            <!-- Next step-button -->
            <standard-button
                v-if="currentStep === IdentifierModalStep.MODE_SELECT"
                color="primary"
                rounded
                :disabled="!tempSelectedMode"
                @click="nextStep"
            >
                {{ $t('global.btn.continue') }}
            </standard-button>

            <!-- Save-button -->
            <standard-button v-else color="primary" rounded :disabled="!valid" @click="saveChannelRelations">
                {{ $t('global.select') }}
            </standard-button>
        </template>
    </Dialog>
</template>
<script lang="ts">
    import Dialog from '@/views/Statistics/ExportStatistics/Modals/Dialog.vue';
    import { defineComponent } from 'vue';
    import type { WidgetIdentifier } from '@/types/widgets.types';
    import ModeSelect from './ModeSelect.vue';
    import { IdentifierModalStep } from '@/types/widgets.types';
    import SingleSelect from './SingleSelect.vue';
    import MultipleSelect from './MultipleSelect.vue';

    export default defineComponent({
        components: {
            Dialog,
            ModeSelect,
            SingleSelect,
            MultipleSelect,
        },
        props: {
            value: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                currentStep: IdentifierModalStep.MODE_SELECT as IdentifierModalStep,
                tempSelectedMode: IdentifierModalStep.SINGLE_SELECT as IdentifierModalStep,
                IdentifierModalStep,
                identifiers: [] as WidgetIdentifier[],
            };
        },
        computed: {
            initialRelations() {
                //@ts-expect-error
                const relations = this.$store.state.BuilderMenu.objectToEdit.properties.main.channelRelations;
                return relations as WidgetIdentifier[];
            },
            headerTitle(): string {
                const titles = {
                    [IdentifierModalStep.MODE_SELECT]: this.$t('widgets.identifierModal.defaultHeaderTitle') as string,
                    [IdentifierModalStep.SINGLE_SELECT]: this.$tc('widgets.identifierModal.headerTitle', 1) as string,
                    [IdentifierModalStep.MULTIPLE_SELECT]: this.$tc('widgets.identifierModal.headerTitle', 2) as string,
                } as const;

                return titles[this.currentStep] || titles[IdentifierModalStep.MODE_SELECT];
            },
            valid() {
                return this.currentStep === IdentifierModalStep.SINGLE_SELECT
                    ? this.validSingleIdentifier()
                    : this.validMultipleIdentifiers();
            },
        },
        watch: {
            value(newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    // when opening modal
                    this.init();
                }
            },
        },
        created() {
            this.init();
        },
        methods: {
            async init() {
                await this.getWidgetQueues();

                this.identifiers = structuredClone(this.initialRelations);

                if (this.identifiers.length === 1) {
                    this.currentStep = IdentifierModalStep.SINGLE_SELECT;
                }
                if (this.identifiers.length > 1) {
                    this.currentStep = IdentifierModalStep.MULTIPLE_SELECT;
                }
            },
            async getWidgetQueues() {
                //@ts-expect-error
                await this.$store.dispatch('Widget/getWidgetQueues');
            },
            async setChannelRelations(identifiers: WidgetIdentifier[]) {
                /** This will update the widget object with the new relations, and automatically save the preview object */

                //@ts-expect-error
                await this.$store.dispatch('Widget/setChannelRelations', identifiers);
            },
            nextStep() {
                this.currentStep = this.tempSelectedMode;
            },
            async saveChannelRelations() {
                await this.setChannelRelations(this.identifiers);
                this.closeModal();
            },
            closeModal() {
                this.$emit('input', false);
            },
            validSingleIdentifier() {
                if (this.identifiers.length !== 1) return false;
                return this.isValidIdentifier(this.identifiers[0], false);
            },
            validMultipleIdentifiers() {
                if (this.identifiers.length < 2) return false;

                return this.identifiers.every((i: WidgetIdentifier) => this.isValidIdentifier(i, true));
            },
            isValidIdentifier(identifier: WidgetIdentifier, needsCategory: boolean) {
                if (!needsCategory) {
                    // Single identifier, no category name
                    return Boolean(identifier.channelIdentifier.length);
                }

                const hasData = identifier.channelIdentifier && identifier.categoryName;
                return hasData;
            },
        },
    });
</script>
<style scoped lang="scss">
    #widget-channel-relation-modal {
        width: 600px;

        transition:
            display 0.1s allow-discrete,
            overlay 0.1s allow-discrete;

        animation: close 0.1s forwards;
        &[open] {
            animation: open 0.1s forwards;
        }

        @keyframes open {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        @keyframes close {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }
</style>
