<template>
    <section class="favourite-email">
        <i class="mdi icon mdi-account"></i>
        <section class="favourite-name">
            <div>
                <span class="favourite-email-name">{{ item.name }}</span>
            </div>
            <div>
                <span class="favourite-email-field">{{ item.emailAddress }}</span>
            </div>
        </section>
    </section>
</template>

<script>
    export default {
        name: 'FavouriteClient',

        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                hover: false,
            };
        },

        computed: {
            favouriteClass() {
                if (this.hover) {
                    return 'mdi-star favourite';
                }

                return this.item.isFavourite ? 'mdi-star favourite' : 'mdi-star-outline not-favourite';
            },
        },

        methods: {
            toggleFavourite() {
                this.$emit('toggle-favourite', this.item);
            },
        },
    };
</script>

<style scoped>
    .favourite-email {
        display: grid;
        grid-template-columns: 48px 1fr;
        padding: 10px 0;
        border-bottom: 1px solid #e0e0e0;
        transition: background-color 0.3s;
        min-height: 60px;
        max-height: 60px;
    }

    .favourite-email:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .favourite-email:active {
        background-color: #e0e0e0;
    }

    .favourite-email > * {
        align-self: center;
    }

    .favourite-email > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        padding-right: 12px;
    }

    .icon {
        font-size: 14px;
        display: flex;
        justify-content: center;
    }

    .not-favourite {
        color: #9e9e9e;
    }

    .favourite-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .favourite-name > div {
        display: flex;
        align-items: center;
        justify-content: stretch;
        flex: 1;
    }

    .favourite-name > div > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0px;
        flex: 1;
    }

    .favourite-email-field {
        color: #9e9e9e;
        font-size: 12px;
    }

    .favourite-email-name {
        font-size: 12px;
    }
</style>
