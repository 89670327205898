<template>
    <section :key="clientId" class="clients-family-container" :style="containerHeightStyle">
        <infinite-list
            ref="list"
            :headers="[]"
            :fetchItems="getClientsFamily"
            :items="clientsFamily"
            :limit="itemsToShow + 1"
            :fetchProps="{ id: clientId }"
            :reverse="true"
            :fixed="true"
            :emptyHeader="$t('clientFamily.empty')"
            :emptyMessage="$t('clientFamily.emptyMessage')"
            emptyIcon="mdi-account-group"
        >
            <template #item="{ item }">
                <ClientFamilyItemV2
                    :item="item"
                    :isSelected="isClientSelected(item.id)"
                    :isAssigned="isClientAssignedToCase(item.id)"
                    @click="handleItemClicked(item)"
                />
            </template>
        </infinite-list>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ClientFamilyItemV2 from './ClientFamilyItemV2.vue';

    export default {
        name: 'ClientFamily',

        components: {
            ClientFamilyItemV2,
        },

        props: {
            clientId: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                itemHeight: 49,
                itemsToShow: 4,
            };
        },

        computed: {
            ...mapState('Client', ['clientsFamily', 'currentSelectedClient']),
            ...mapState('Cases', ['selectedCase']),

            containerHeight() {
                return `${Math.min(this.clientsFamily.length * this.itemHeight, this.itemHeight * this.itemsToShow)}px`;
            },

            containerHeightStyle() {
                return {
                    minHeight: this.containerHeight,
                    maxHeight: this.containerHeight,
                };
            },
        },

        watch: {
            clientId() {
                for (const client of this.clientsFamily) {
                    this.unsubscribeToClient(client.id);
                }
            },
        },

        mounted() {
            this.getTimeline({ id: this.clientId, page: 1, limit: 10 });
        },

        beforeDestroy() {
            for (const client of this.clientsFamily) {
                this.unsubscribeToClient(client.id);
            }
        },

        methods: {
            ...mapActions('Client', ['getClientsFamily', 'getClient', 'getTimeline', 'unsubscribeToClient']),

            handleItemClicked(item) {
                this.getClient(item.id);
                this.getTimeline({ id: item.id, page: 1, limit: 50 });
                this.$refs.list.scrollToItemId(item.id);
            },
            isClientSelected(clientId) {
                return clientId === this.currentSelectedClient?.clientId;
            },
            isClientAssignedToCase(clientId) {
                return clientId === this.selectedCase?.case?.clientId;
            },
        },
    };
</script>

<style scoped>
    .clients-family-container {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        overflow: hidden;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientFamily": {
            "empty": "No Family",
            "emptyMessage": "This client has no family"
        }
    },
    "sv": {
        "clientFamily": {
            "empty": "Ingan familj",
            "emptyMessage": "Denna klient har ingen familj"
        }
    }
}
</i18n>
