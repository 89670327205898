import axios from 'axios';

function publish(q) {
    return axios.post('/v1/social-networks/publish/', {
        text: q.text,
        caseId: q.caseId,
        userId: q.userId,
    });
}

function updateComment(itemId, data) {
    return axios.put(`/v1/social-networks/comment/${itemId}`, data);
}

export default {
    publish,
    updateComment,
};
