import { httpStatusCode } from '@/enums/response.enums';
import axios from 'axios';
import z from 'zod';

export async function setDivertOnUser(userId: number, body: { divert: number; endTime?: string }) {
    const response = await axios.put(`/users/divert/${userId}`, body);
    return response.status === httpStatusCode.OK ? response.data : null;
}
export async function getDivertOnUser(userId: number) {
    const response = await axios.get(`/users/divert/${userId}`);

    const result = z.number().safeParse(response.data);

    if (!result.success) {
        console.error('Validation failed on get divert on user', result.error);
        return null;
    }

    return result.data;
}
