<template>
    <section ref="bar" class="tool-bar-container" @mousedown="startDrag" cy-data="create-comments-toolbar">
        <section class="tool-bar" cy-data="create-comments-toolbar-header">
            <v-icon size="16" cy-data="create-comments-toolbar-header-icon">mdi-drag-horizontal</v-icon>
        </section>
    </section>
</template>

<script lang="ts">
    import { defineComponent, ref, inject, onUnmounted, onMounted } from 'vue';
    import { CreateCommentsState } from '@/types/createComments.state';

    export default defineComponent({
        name: 'CreateCommentsTab',
        setup() {
            const commentsState = inject<CreateCommentsState>('commentsState');

            if (!commentsState) {
                throw new Error('commentsState is not provided');
            }

            const bar = ref<HTMLElement | null>(null);
            const isDragging = ref(false);
            const startY = ref(0);

            const startDrag = (event: MouseEvent) => {
                isDragging.value = true;
                startY.value = event.clientY;

                document.addEventListener('mousemove', onDrag);
                document.addEventListener('mouseup', stopDrag);
            };

            const onDrag = (event: MouseEvent) => {
                if (!isDragging.value) return;

                const conversationContainer = document.querySelector('#case-content');

                if (!conversationContainer) return;

                const conversationContainerHeight = conversationContainer.clientHeight - 85 * 2;
                const maxHeight = conversationContainerHeight;

                const deltaY = event.clientY - startY.value;
                const heightToSet = Math.max(50, commentsState.minHeight - deltaY);
                commentsState.minHeight = Math.min(maxHeight, heightToSet);

                startY.value = event.clientY;
            };

            const stopDrag = () => {
                isDragging.value = false;
                document.removeEventListener('mousemove', onDrag);
                document.removeEventListener('mouseup', stopDrag);
            };

            onUnmounted(() => {
                document.removeEventListener('mousemove', onDrag);
                document.removeEventListener('mouseup', stopDrag);
            });

            onMounted(() => {
                const conversationContainer = document.querySelector('#case-content');

                if (!conversationContainer) return;

                const conversationContainerHeight = conversationContainer.clientHeight - 85 * 2;
                const maxHeight = conversationContainerHeight;

                if (commentsState.minHeight > maxHeight) {
                    commentsState.minHeight = maxHeight;
                }
            });

            return {
                bar,
                commentsState,
                startDrag,
            };
        },
    });
</script>

<style scoped>
    .tool-bar-container {
        width: 100%;
        height: 12px;
        cursor: ns-resize;
    }

    .tool-bar {
        background-color: var(--v-primary-base);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12px;
        width: 100%;
        border-radius: 4px 4px 0 0;
    }

    .tool-bar > :first-child {
        font-size: 0.9rem;
        color: white;
    }

    .tool-bar > * {
        align-self: center;
    }

    .tool-bar-header {
        user-select: none;
    }
</style>
