<template>
    <section class="email-multi-container" :class="{ disabled: disabled }">
        <input type="text" autocomplete="off" style="display: none" tabindex="0" />
        <v-combobox
            name="email-combobox"
            v-bind="buttonPropsComputed"
            :dense="dense"
            no-filter
            hide-selected
            hide-no-data
            cy-data="emailInputBox"
            :items="availableEmails"
            :search-input.sync="search"
            :loading="loading"
            autocomplete="off"
            @input="handleChange"
        >
            <template #item="{ item }">
                <v-list-item-content>
                    <div>{{ getClientName(item) }} ({{ item }})</div>
                </v-list-item-content>
            </template>
            <template v-if="validate" #selection="{ attrs, item, parent }">
                <v-chip
                    v-bind="attrs"
                    :color="validateEmail(item) ? 'primary' : 'error'"
                    label
                    small
                    class="email-chip"
                >
                    <span class="pr-1 text-truncat chip-text">{{ item }}</span>
                    <v-icon size="8" @click="parent.selectItem(item)">mdi-close</v-icon>
                </v-chip>
            </template>

            <template slot="prepend-inner">
                <slot name="prepend-inner"></slot>
            </template>

            <template #append>
                <section class="append-inner">
                    <slot name="append-inner"></slot>
                    <Favourites :queueId="queueId" @insert-favourite="insertFavourite" />
                </section>
            </template>
        </v-combobox>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debouncer, validateEmail } from '@/utils';
    import { searchFields, clientTypes } from '../../../enums/client.enums';

    import Favourites from './Favourites.vue';

    export default {
        name: 'EmailMulti',

        components: {
            Favourites,
        },

        props: {
            icon: {
                type: String,
                default: '',
            },
            dense: {
                type: Boolean,
                default: false,
            },
            validate: {
                type: Boolean,
                default: false,
            },
            queueId: {
                type: Number,
                default: 0,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    flat: true,
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    chips: true,
                    multiple: true,
                },

                search: null,
                ruleStatus: '',
                contactPersons: [],
                loading: false,
                searchData: [],
                debounceGetData: null,
                input: '',
                clientData: null,
            };
        },

        computed: {
            ...mapState({
                currentFormData: (state) => state.Creator.currentFormData,
            }),

            // Get all available emails
            availableEmails() {
                try {
                    const clientEmails = this.getClientEmails();
                    const availableEmails = new Set(clientEmails);

                    for (const email of this.searchData) {
                        availableEmails.add(email);
                    }

                    return Array.from(availableEmails);
                } catch (error) {
                    return [];
                }
            },

            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                    dense: this.dense,
                    solo: true,
                    rules: [],
                };
            },
        },

        watch: {
            search: {
                handler() {
                    this.debounceGetData();
                },
            },
        },

        mounted() {
            const DEBOUNCE_TIME = 500;
            this.debounceGetData = debouncer(this.getData, DEBOUNCE_TIME);
            this.input = this.$attrs.value || [];
        },
        methods: {
            validateEmail,
            ...mapActions({
                searchClients: 'Client/getClients',
            }),

            getClientName(email) {
                if (!this.clientData) {
                    return this.getClientNameFromCard(email);
                }

                const client = this.clientData.clients.find((client) => {
                    return client.data.primaryEmail === email || client.data.secondaryEmail === email;
                });

                if (!client) {
                    return this.getClientNameFromCard(email);
                }

                return client.data.name;
            },

            async getData() {
                if (!this.search) {
                    this.searchData = [];
                    this.clientData = null;
                    return;
                }

                this.loading = true;
                const data = await this.searchClients({
                    search: this.search,
                    searchFields: [searchFields.NAME, searchFields.EMAIL],
                    types: [clientTypes.CLIENT, clientTypes.SHADOW],
                });

                if (!data) {
                    this.searchData = [];
                    this.clientData = null;
                    this.loading = false;
                    return;
                }

                this.clientData = data;

                const allEmails = data.clients
                    .flatMap((client) => [client.data.primaryEmail, client.data.secondaryEmail])
                    .filter(Boolean);

                this.searchData = allEmails;
                this.loading = false;
            },

            insertFavourite(email) {
                this.input = [...new Set([...this.input, email])];
                this.$emit('change', this.input);
                this.$emit('input', this.input);
            },

            // Extract email from a "mailbox" string, ie a string that contains an email address in the format "Full Name <email@example.org>"
            extractEmailFromMailboxString(mailboxString) {
                const emailRegex = /<(\S+@\S+\.\S+)>/g;
                const match = emailRegex.exec(mailboxString);
                return match?.[1] ?? mailboxString;
            },

            handleChange(value) {
                const rawEmails = value.map((email) => this.extractEmailFromMailboxString(email).trim());
                const emails = [...new Set(rawEmails)];

                if (emails?.length === 0) {
                    this.$emit('change', '');
                    this.$emit('input', '');
                    this.search = '';
                    return;
                }

                this.input = emails;
                this.$emit('change', this.input);
                this.$emit('input', this.input);
                this.search = '';
            },

            handleClick(event) {
                this.$emit('click', event);
            },
            getClientEmails() {
                const clientEmails = new Set();
                const client = this.currentFormData?.client?.data;
                if (client?.primaryEmail) {
                    clientEmails.add(client.primaryEmail);
                }
                if (client?.secondaryEmail) {
                    clientEmails.add(client.secondaryEmail);
                }
                if (client?.contacts) {
                    for (const contact of client.contacts) {
                        const { primaryEmail, secondaryEmail } = contact;
                        if (primaryEmail) {
                            clientEmails.add(primaryEmail);
                        }
                        if (secondaryEmail) {
                            clientEmails.add(secondaryEmail);
                        }
                    }
                }

                return [...clientEmails].filter((email) => email.includes(this.search));
            },

            getClientNameFromCard(email) {
                const client = this.currentFormData?.client?.data;

                if (client?.primaryEmail === email) {
                    return client.name;
                }

                if (client?.secondaryEmail === email) {
                    return client.name;
                }

                if (client?.contacts) {
                    for (const contact of client.contacts) {
                        const { primaryEmail, secondaryEmail, name } = contact;
                        if (primaryEmail === email) {
                            return name;
                        }
                        if (secondaryEmail === email) {
                            return name;
                        }
                    }
                }

                return '';
            },
        },
    };
</script>

<style scoped>
    .append-inner {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    :deep(.v-btn__content) {
        letter-spacing: 0px !important;
        text-transform: none !important;
        font-weight: 400 !important;
        min-height: 20px;
    }

    .btn {
        color: var(--v-gray2-base);
        font-size: 1rem;
    }
    .email-multi-container {
        display: flex;
        flex-wrap: wrap;
    }
    .email-chip {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        max-width: fit-content;
    }
    .chip-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
