import i18n from '../i18n';
/**
 * @param {Object} to - Router to object.
 * @param {Object} from - Router from object.
 * @param {Function} next - Router next() navigation.
 * @summary Sets HTML <title> tag based on the route name.
 * @author Taieb Khessib
 * @since 2023-10-19
 */

export default function title(to, next) {
    const DEFAULT_TITLE = 'Customer First - Contact Center';

    if (to.path.includes('/tab')) {
        document.title = i18n.t('router.tabTitle') + ' ' + to.params.caseId;
    } else if (to.meta?.name) {
        document.title = i18n.t(to.meta.name) + ' | ' + DEFAULT_TITLE;
    } else {
        document.title = DEFAULT_TITLE;
    }

    next();
}
