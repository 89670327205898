<template>
    <section id="subcase-sidebar-wrapper">
        <SubCaseSideBar :caseId="caseId" :subCases="subCases" :expanded="expanded" />
        <ExpandButton v-model="expanded" id="expand-button" />
    </section>
</template>

<script lang="ts">
    import SubCaseSideBar from '@/components/Cases/Subcases/SubCaseSideBar/SubCaseSideBar.vue';
    import ExpandButton from '@/components/Cases/Subcases/SubCaseSideBar/ExpandButton.vue';

    import { defineComponent, type PropType } from 'vue';
    import type { Case } from '@/types/cases.types';

    export default defineComponent({
        components: {
            SubCaseSideBar,
            ExpandButton,
        },
        props: {
            caseId: {
                type: String,
                required: true,
            },
            subCases: {
                type: Array as PropType<Case[]>,
                default: () => [],
            },
        },
        data() {
            return {
                expanded: false as boolean,
            };
        },
        computed: {
            caseTravelState() {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Cases.caseTravelState as boolean;
            },
        },
        watch: {
            caseTravelState(newVal) {
                // Collapse this component when case travel is expanded
                if (newVal) {
                    this.expanded = false;
                }
            },
            expanded(newVal) {
                if (newVal) {
                    // @ts-ignore <This is going to be converted to Pinia store in the future>
                    this.$store.dispatch('Cases/setCaseTravelMinimized', false);
                }
            },
        },
    });
</script>
<style scoped lang="scss">
    #subcase-sidebar-wrapper {
        position: relative;
        display: flex;

        #expand-button {
            opacity: 0;
            transition: opacity 0.2s ease;

            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);

            z-index: 100;
        }

        &:hover #expand-button {
            opacity: 1;
        }
    }
</style>
