<template>
    <section v-if="queueId" class="favourite-container">
        <InfiniteMenu
            :headers="[]"
            :fetchFunction="getQueueEmails"
            :fetchProps="{ queueId }"
            :items="queueEmails"
            :limit="30"
            :queueId="queueId"
            :emptyHeader="$t('emptyHeader')"
            :emptyMessage="$t('emptyMessage')"
            :isSearchable="true"
            @click-item="insertFavourite"
        >
            <IconButton icon="mdi-list-box" />
            <template #item="{ item }">
                <FavouriteClient :item="item" @toggle-favourite="toggleFavourite" />
            </template>
        </InfiniteMenu>

        <InfiniteMenu
            :headers="[]"
            :fetchFunction="getCombinedEmails"
            :fetchProps="{ queueId }"
            :items="combinedEmails"
            :limit="30"
            :queueId="queueId"
            :emptyHeader="$t('emptyHeader')"
            :emptyMessage="$t('emptyMessage')"
            :isSearchable="true"
            @click-item="insertFavourite"
        >
            <IconButton icon="mdi-star" />
            <template #item="{ item }">
                <FavouriteEmail :item="item" @toggle-favourite="toggleFavourite" />
            </template>
        </InfiniteMenu>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import InfiniteMenu from '@/components/Main/List/InfiniteMenu.vue';
    import IconButton from '@/components/Main/Buttons/Icon.vue';
    import FavouriteEmail from '@/components/Favourites/FavouriteEmail.vue';
    import FavouriteClient from '@/components/Favourites/FavouriteClient.vue';

    export default {
        name: 'Favourites',

        components: {
            InfiniteMenu,
            IconButton,
            FavouriteEmail,
            FavouriteClient,
        },

        props: {
            queueId: {
                type: Number,
                default: null,
            },
        },

        computed: {
            ...mapState('Favourites', ['combinedEmails', 'queueEmails']),
        },

        methods: {
            ...mapActions('Favourites', ['getCombinedEmails', 'getQueueEmails', 'addFavourite', 'removeFavourite']),

            toggleFavourite(item) {
                if (item.isFavourite) {
                    this.removeFavourite({ queueId: this.queueId, email: item.emailAddress });
                } else {
                    this.addFavourite({ queueId: this.queueId, email: item.emailAddress });
                }
            },

            insertFavourite(item) {
                if (!item.emailAddress) {
                    return;
                }
                this.$emit('insert-favourite', item.emailAddress);
            },
        },
    };
</script>

<style scoped>
    .favourite-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4px;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "emptyHeader": "Inga favoriter",
        "emptyMessage": "Inga favoriter har lagts till i listan"
    },

    "en": {
        "emptyHeader": "No favourites",
        "emptyMessage": "No favourites have been added to the list"
    }
}
</i18n>
