<template>
    <div id="chatGPTContainer">
        <NotPurchased
            :dialog="notPurchasedDialog"
            :heading="'AI + Customer First'"
            @close="notPurchasedDialog = false"
        />

        <PopupMenu
            v-if="hasAiRole"
            :key="notPurchasedDialog"
            :items="menuItems"
            heading="C1 AI"
            :multiSelect="false"
            :state="[{ value: selectedItem.value }]"
            returnObject
            width="auto"
            :closeOnSelect="true"
            :searchAble="true"
            :isDisabled="isLoading || disabled"
            @input="(v) => command(v)"
        >
            <template #button>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            fab
                            x-small
                            elevation="0"
                            class="mx-0 mb-0 pa-0 softShadow cursor-pointer"
                            :loading="isLoading"
                            :class="buttonClass"
                            icon
                            color="primary"
                            v-on="on"
                            @click="init(context)"
                        >
                            <v-img
                                :class="{ 'small-button-icon': small }"
                                :width="iconSizeLocal"
                                color="primary"
                                src="@/assets/General/AI/c1_ai.svg"
                            />
                        </v-btn>
                    </template>
                    <span>C1 AI</span>
                </v-tooltip>
            </template>
            <template #default="{ item }">
                <div class="d-flex flex-row align-center">
                    <v-img max-width="16px" class="mr-3" color="primary" src="@/assets/General/AI/c1_ai.svg" />
                    <span class="custom-font-size">{{ item.summary }}</span>
                </div>
            </template>
        </PopupMenu>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { httpStatusCode } from '@/enums/response.enums';

    export default {
        name: 'ChatGPT',
        components: {
            NotPurchased: () => import('@/components/Global/ChatGPT/NotPurchased.vue'),
            PopupMenu: () => import('@/components/Cases/Navigation/PopupMenu.vue'),
        },
        props: {
            small: {
                type: Boolean,
                default: false,
            },
            context: {
                type: String,
                default: 'cases',
            },
            commentId: {
                type: Number,
                default: 0,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            conversation: {
                type: Array,
                default: () => [],
            },
            iconSize: {
                type: String,
                default: '16px',
            },
            // manual input text, if source text is not stored in store
            textInput: {
                type: String,
                default: '',
            },
            caseId: {
                type: String,
                default: undefined,
            },
            clientId: {
                type: String,
                default: undefined,
            },
        },
        data() {
            return {
                selectedItem: { value: 0 },
                notPurchasedDialog: false,
            };
        },
        computed: {
            ...mapState({
                isLoading: (state) => state.Chatgpt.isLoading,
                availablePrompts: (state) => state.Chatgpt.prompts,
                menuItems: (state) => state.Chatgpt.menuItems,
                userRoles: (state) => state.Auth.roles,
                tiptapState: (state) => state.Comments.tiptapState,
            }),

            iconSizeLocal() {
                return this.small ? '12px' : this.iconSize;
            },

            hasAiRole() {
                const AI_ROLE = 5;
                return !!this.userRoles.some((role) => role >= AI_ROLE);
            },

            buttonClass() {
                return this.small ? 'small-button' : '';
            },
        },
        sockets: {
            GPTpartialTextResponse(data) {
                try {
                    this.$emit('result', data.text);
                } catch (error) {
                    console.error('Error in GPTpartialTextResponse socket', error);
                }
            },
        },
        methods: {
            ...mapActions({
                executeAnswerButton: 'Forwarder/executeAnswerButton',
            }),

            async init(context) {
                const state = await this.$store.dispatch('Chatgpt/getAvailablePrompts', context);

                if (state === httpStatusCode.FORBIDDEN) {
                    this.notPurchasedDialog = true;

                    return state;
                }
                return state;
            },

            async command(item) {
                this.selectedItem = item;

                // If tiptap is not open, open it (same as pressing the answer button)
                // we only want to do this if the context is comment
                if (this.tiptapState === 'closed' && this.$props.context === 'comment') {
                    this.executeAnswerButton();
                }

                const result = await this.$store.dispatch('Chatgpt/command', {
                    id: item.id,
                    context: this.context,
                    commentId: this?.commentId || undefined,
                    stream: true,
                    textInput: this.textInput || undefined,
                    conversation: this.conversation || [],
                    ...(this.caseId || this.clientId || this.commentId
                        ? {
                              meta: {
                                  ...(this.caseId && { caseId: this.caseId }),
                                  ...(this.clientId && { clientId: this.clientId }),
                                  ...(this.commentId && { commentId: this.commentId }),
                              },
                          }
                        : {}), // Looks complex, but the only thing it does is to unclude meta if either caseId or clientId or commentId exists
                });

                if (typeof result !== 'string') {
                    this.$toasted.show(result.response.data, {
                        type: 'error',
                        duration: 5000,
                    });
                    return;
                }

                this.$emit('result', result);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .small-button-icon {
        max-width: 12px;
        max-height: 12px;
    }

    .small-button {
        background-color: #e1f0f4;
    }

    #chatGPTContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-font-size {
        font-size: 12px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .text-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px; /* optional - set a max width to prevent text from overflowing */
    }

    .icon-image {
        max-width: 20px;
    }

    .summary-text {
        font-size: 12px;
    }

    .ai-button {
        cursor: pointer;
    }
</style>
