import { defineStore } from 'pinia';

export const useCommentsStore = defineStore('comments', {
    state: () => ({
        isFullScreenView: false,
    }),

    actions: {
        setFullScreenView() {
            this.isFullScreenView = true;
        },
    },
});
