<template>
    <main @mouseover="mouseOver = true" @mouseleave="mouseOver = false">
        <v-navigation-drawer
            ref="target"
            style="z-index: 101; position: fixed"
            :class="sideBar !== sideBarState.STANDARD ? 'mini-variant' : ''"
            height="100%"
            permanent
            mini-variant-width="72px"
            :expand-on-hover="sideBar === sideBarState.STANDARD && !sideBarPinned"
            clipped
            @transitionend="toggleTransition"
        >
            <template #prepend>
                <section class="nav-top-row">
                    <UserContainer :sidebarOpen="mouseOver || sideBarPinned" />
                </section>
            </template>

            <Transition mode="out-in" name="slide-fade">
                <v-sheet v-if="sideBar === sideBarState.STANDARD" height="99%" class="sidebar-container">
                    <v-sheet>
                        <RecursiveList isSidebar hasPopOuts :isSidebarPinned="sideBarPinned" />
                    </v-sheet>

                    <section class="logo-row">
                        <div class="logo-box">
                            <CustomTooltip v-if="!isSmartSearchActive" location="right" openDelay="500" maxWidth="250">
                                <template #button>
                                    <v-img src="@/assets/General/C1_icon.svg" class="logo-img" />
                                </template>
                                <template #content>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content v-show="isOpen" style="padding: 0">
                                                <div>© Customer First AB</div>
                                                <div class="text-caption text-truncate">Made by {{ developer }}.</div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </CustomTooltip>
                            <div v-else class="ai-icon">
                                <v-img color="primary" src="@/assets/General/AI/c1_ai.svg" @click="openSmartSearch" />
                            </div>
                        </div>
                    </section>

                    <section class="nav-row">
                        <section class="nav-footer">
                            <NavLink
                                v-if="sentryEnabled"
                                id="help-button"
                                :dense="true"
                                :isTextVisible="mouseOver || sideBarPinned"
                                icon="mdi-help-circle-outline"
                            >
                                {{ $t('sidebar.reportBug') }}
                            </NavLink>
                            <NavLink
                                :dense="true"
                                :isTextVisible="mouseOver || sideBarPinned"
                                @click="handlePinSidebar"
                            >
                                {{ sideBarPinned ? $t('sidebar.unpinSidebar') : $t('sidebar.pinSidebar') }}
                                <template #icon>
                                    <v-img v-if="sideBarPinned" src="@/assets/Icons/unpin_sidebar.svg" />
                                    <v-img v-else src="@/assets/Icons/pin_sidebar.svg" />
                                </template>
                            </NavLink>

                            <NavLink
                                :dense="true"
                                :isTextVisible="mouseOver || sideBarPinned"
                                icon="mdi-logout"
                                @click="$store.dispatch('Auth/logoutClient')"
                            >
                                {{ $t('sidebar.signOut') }}
                            </NavLink>
                        </section>
                    </section>
                    <div class="text-left pl-5 version">v{{ revision }}</div>
                </v-sheet>

                <v-sheet
                    v-if="sideBar === sideBarState.BUILDER"
                    height="99%"
                    class="d-flex flex-column justify-space-between"
                >
                    <BuilderMenu />
                    <div class="text-left pl-5 version">v{{ revision }}</div>
                </v-sheet>
            </Transition>
        </v-navigation-drawer>
    </main>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import RecursiveList from '@/components/Global/MenuList/RecursiveList.vue';
    import BuilderMenu from '@/components/Global/BuilderMenu.vue';
    import CustomTooltip from '@/components/Global/CustomTooltip.vue';
    import NavLink from '@/components/CustomerFirst/Buttons/NavLink.vue';
    import UserContainer from '@/components/CustomerFirst/User/UserContainer.vue';
    import { revision } from '@/app/config';
    import { feedback } from '@/sentry';

    export default {
        name: 'Sidebar',
        components: {
            RecursiveList,
            BuilderMenu,
            CustomTooltip,
            NavLink,
            UserContainer,
        },
        data() {
            return {
                isOpen: false,
                mouseOver: false,
                developer: '',
                developersName: [
                    'Teodor',
                    'Benjamin',
                    'Edvin',
                    'Tim S',
                    'Tim A',
                    'Viktor B',
                    'Victor A',
                    'Taieb',
                    'Erik',
                    'Jesper',
                    'Markus',
                    'Samuel',
                ],
                developersPlanet: [
                    'Mercury',
                    'Venus',
                    'Earth',
                    'Mars',
                    'Jupiter',
                    'Saturn',
                    'Uranus',
                    'Neptune',
                    'Pluto',
                ],
                sideBarPinned: false,
                revision,
                sentryEnabled: false,
            };
        },
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
                sideBar: (state) => state.Layout.sideBar,
                sideBarState: (state) => state.Layout.sideBarState,
                isSideBarPinned: (state) => state.Layout.isSideBarPinned,
                roles: (state) => state.Auth.roles,
                widgetBuilderState: (state) => state.Widget.widgetBuilderState,
                globalWallboardState: (state) => state.Wallboard.globalWallboardState,
            }),
            isSmartSearchActive() {
                if (this.roles && this.roles.length > 0) {
                    return this.roles.includes(99); // 99 is the role for the AI assistant
                }
                return false;
            },
        },
        watch: {
            isOpen() {
                this.chooseNewDeveloper();
            },
        },
        created() {
            // this.openSmartSearch();
            if (typeof this.isSideBarPinned === 'boolean') this.sideBarPinned = this.isSideBarPinned;
            this.$emit('sidebar-pinned', this.sideBarPinned);
        },
        mounted() {
            this.getSentryEnabled();
            feedback.attachTo(document.querySelector('#help-button'), {
                formTitle: this.$t('sentry.formTitle'),
                triggerLabel: this.$t('sentry.formTitle'),
                triggerAriaLabel: this.$t('sentry.formTitle'),
                submitButtonLabel: this.$t('sentry.submitButtonLabel'),
                cancelButtonLabel: this.$t('sentry.cancelButtonLabel'),
                confirmButtonLabel: this.$t('sentry.confirmButtonLabel'),
                addScreenshotButtonLabel: this.$t('sentry.addScreenshotButtonLabel'),
                removeScreenshotButtonLabel: this.$t('sentry.removeScreenshotButtonLabel'),
                nameLabel: this.$t('sentry.nameLabel'),
                namePlaceholder: this.$t('sentry.namePlaceholder'),
                emailLabel: this.$t('sentry.emailLabel'),
                emailPlaceholder: this.$t('sentry.emailPlaceholder'),
                isRequiredLabel: this.$t('sentry.isRequiredLabel'),
                messageLabel: this.$t('sentry.messageLabel'),
                messagePlaceholder: this.$t('sentry.messagePlaceholder'),
                successMessageText: this.$t('sentry.successMessageText'),
            });
        },
        methods: {
            ...mapActions('SmartSearch', ['openSmartSearch']),
            async getSentryEnabled() {
                const enabled = await this.$store.dispatch('System/getSentryEnabled');
                this.sentryEnabled = enabled;
            },
            returnRandomDeveloper() {
                return this.developersName[Math.floor(Math.random() * this.developersName.length)];
            },
            returnRandomPlanet() {
                return this.developersPlanet[Math.floor(Math.random() * this.developersPlanet.length)];
            },
            chooseNewDeveloper() {
                this.developer = `<b>${this.returnRandomDeveloper()}</b> on <b>${this.returnRandomPlanet()}</b>`;
            },
            toggleTransition() {
                this.isOpen = this.$refs.target?.isMouseover;
            },
            handlePinSidebar() {
                this.sideBarPinned = !this.sideBarPinned;
                this.$store.dispatch('Layout/setIsSideBarPinned', this.sideBarPinned);
                this.$emit('sidebar-pinned', this.sideBarPinned);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .version {
        font-size: 8px;
    }
    .ai-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
        cursor: pointer;
    }

    .mini-variant {
        max-width: 72px;
    }
    .btn {
        padding: 0 0.6em;
        margin: 0 1em;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--v-primary-base);
    }

    .logo-row {
        display: flex;
        justify-content: center;
        padding: 16px;
    }
    .logo-box {
        width: 33px;
        height: 36px;
    }
    .logo-img {
        margin: 0 !important;
        height: 100%;
        display: flex;
        align-self: center;
    }
    .v-list-item-icon_override {
        margin: 0 !important;
        height: 100%;
        display: flex;
        align-self: center;
    }
    .slide-fade-enter-active {
        transition: all 0.25s ease-in-out;
    }
    .slide-fade-leave-active {
        transition: all 0.5s ease-in-out;
    }
    .slide-fade-enter {
        transform: translateX(-10px);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    .nav-footer {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        border-top: 1px solid var(--v-gray3-darken1);
        padding-top: 16px;
        flex: 1;
    }

    .nav-row {
        display: flex;
        padding: 16px;
        width: 100%;
    }

    .nav-top-row {
        padding: 8px;
    }

    .sidebar-container {
        display: grid;
        grid-template-rows: 1fr auto auto;
    }
</style>
<i18n lang="json">
{
    "en": {
        "sidebar": {
            "signOut": "Sign out",
            "externalLinks": "External links",
            "pinSidebar": "Pin sidebar",
            "unpinSidebar": "Unpin sidebar",
            "reportBug": "Report a bug"
        }
    },
    "sv": {
        "sidebar": {
            "signOut": "Logga ut",
            "externalLinks": "Externa länkar",
            "pinSidebar": "Fäst sidomenyn",
            "unpinSidebar": "Lossa sidomenyn",
            "reportBug": "Rapportera ett fel"
        }
    }
}
</i18n>
