<template>
    <section class="create-comments-actions">
        <CreateCommentsFunctions cy-data="create-comments-functions" />
        <CreateCommentsSendButtons cy-data="create-comments-send-buttons" />
    </section>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import CreateCommentsFunctions from '@/components/Cases/CreateComments/CreateCommentsFunctions.vue';
    import CreateCommentsSendButtons from '@/components/Cases/CreateComments/CreateCommentsSendButtons.vue';

    export default defineComponent({
        name: 'CreateCommentsAction',

        components: {
            CreateCommentsFunctions,
            CreateCommentsSendButtons,
        },
    });
</script>

<style scoped>
    .create-comments-actions {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        padding-top: 8px;
    }
</style>
