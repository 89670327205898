import files from '@/api/files/files';
import { formatFile } from '@/helpers/files/files.helper';

import { v4 as uuidv4 } from 'uuid';

const getDefaultState = () => ({
    files: [],
});
// State object
const state = {
    ...getDefaultState(),
};

const getters = {
    /**
     * @typedef {Object} Item
     * @property {string} caseId - The case ID.
     * @property {string} file - The file
     * @property {string} id - The file ID.
     * @property {string} type - The file type (file or reference).
     */
    getFilesByCaseId: (state) => (caseId) => {
        return state.files.filter((file) => file.caseId === caseId);
    },
};

// Actions
const actions = {
    /**
     * @typedef {Object} Payload
     * @property {string} caseId - The case ID.
     * @property {files} files - The files associated with the payload.
     */
    setFilesToCase({ commit }, { files, caseId }) {
        commit('CLEAR_ALL_FILES', caseId);
        for (const file of files) {
            if (file.file) {
                file.caseId = caseId;
                commit('ADD_FILES_TO_CASE', [file]);
                continue;
            }

            const formattedFile = formatFile(file);
            const fileItem = {
                file: formattedFile,
                caseId,
                id: uuidv4(),
                type: 'reference',
            };
            commit('ADD_FILES_TO_CASE', [fileItem]);
        }
    },

    removeFileFromCase({ commit }, id) {
        commit('REMOVE_FILE_FROM_CASE', id);
    },

    clearAllFiles({ commit }, caseId) {
        commit('CLEAR_ALL_FILES', caseId);
    },
    async getAttachmentFile(_, { id, meta }) {
        try {
            const res = await files.getAttachmentFile(id, meta);
            if (res.status === 200) {
                return res.data;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    async getAttachmentFileByContentId(_, contentId) {
        try {
            const res = await files.getAttachmentFileByContentId(contentId);
            if (res.status === 200) {
                return res.data;
            }
            return null;
        } catch (error) {
            // Attachment not found
            return null;
        }
    },
    async uploadAttachments(_, attachments) {
        try {
            const res = await files.uploadAttachments(attachments);
            if (res.status === 200) {
                return res.data;
            }
            return null;
        } catch (error) {
            console.error('File Store -> Error when uploading attachment.', error);
            return null;
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
};

// Mutations
const mutations = {
    ADD_FILES_TO_CASE(state, payload) {
        state.files.push(...payload);
    },

    REMOVE_FILE_FROM_CASE(state, id) {
        state.files = state.files.filter((file) => file.id !== id);
    },

    CLEAR_ALL_FILES(state, caseId) {
        if (!caseId) {
            state.files = [];
            return;
        }
        state.files = state.files.filter((file) => file.caseId !== caseId);
    },

    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
