import i18n from '@/i18n';
import { internationalOrInternalNumberRegex, internationalNumberRegex } from '@/utils/index';
import { inputTypes, inputTypesKeys, formTypes } from '@/enums/creator.enums';

const TRUTHY_RETURN = { valid: true, text: null };

// Get the validation based on certain components
export function getRule(item) {
    if (!item) {
        return [];
    }

    const rules = [];

    // Handle specific types of unique inputs based on keys
    switch (item.type) {
        case inputTypesKeys.SOCIAL_SECURITY_NUMBER_INPUT: {
            rules.push(validateSocialSecurityNumber);
            break;
        }
        case inputTypesKeys.TELEPHONE_INPUT: {
            rules.push(validateTelephoneNumber);
            break;
        }
        case inputTypesKeys.SMS_INPUT: {
            rules.push(validateSms);
            break;
        }
        default: {
            break;
        }
    }

    switch (item.component) {
        case inputTypes.ADDRESS_INPUT:
        case inputTypes.INPUT: {
            rules.push(validateIsStringOrEmpty);
            break;
        }

        case inputTypes.MULTI_SELECT:
        case inputTypes.SINGLE_SELECT: {
            break;
        }
        case inputTypes.TEXT: {
            rules.push(validateIsString);
            break;
        }
        case inputTypes.EMAIL_INPUT_SYSTEM:
        case inputTypes.EMAIL_INPUT_SINGLE:
        case inputTypes.EMAIL_INPUT_MULTI: {
            rules.push(validateEmail);
            break;
        }
        case inputTypes.SMS_TIP_TAP: {
            rules.push(validateNoImages, validateTipTapLength);
            break;
        }
        case inputTypes.STATUS_SELECT:
        case inputTypes.QUEUE_SELECT:
        case inputTypes.TIME_SELECT:
        case inputTypes.SLA_SELECT:
        case inputTypes.USER_SELECT:
        case inputTypes.CLIENT_SELECT:
        case inputTypes.TIP_TAP: {
            break;
        }
        default: {
            break;
        }
    }

    if (item.required) {
        rules.push(validateRequired);

        if (item.component === inputTypes.TIP_TAP) {
            rules.push(validateRequiredStringTrimmed);
        }
    }

    return rules;
}

// Validate whether a value is a string

export function validateIsStringOrEmpty(value) {
    if (typeof value === 'string' && value.trim() === '') {
        return { valid: false, text: i18n.t('creator.validation.valueRequired') };
    }

    if (typeof value === 'string' || value === '' || value === null || value === undefined) {
        return TRUTHY_RETURN;
    }
    return { valid: false, text: i18n.t('creator.validation.valueRequired') };
}

// Validates a telephone number. Allows international and internal numbers
function validateTelephoneNumber(value) {
    // If the value is null, return true
    if (!value) {
        return TRUTHY_RETURN;
    }
    const regex = internationalOrInternalNumberRegex;
    return regex.test(value);
}

function validateRequiredStringTrimmed(value) {
    let content = value?.content || '';

    if (content.includes('<p')) {
        content = content
            .replace(/<p[^>]*>/i, '')
            .replace(/<\/p>/i, '')
            .trim();
    }

    if (content === '' || content === null || content === undefined) {
        return { valid: false, text: i18n.t('creator.validation.valueRequired') };
    }

    return TRUTHY_RETURN;
}

function validateSms(value) {
    if (!value) {
        return true;
    }
    const regex = internationalNumberRegex;
    const validPhoneNumber = regex.test(value);
    if (validPhoneNumber) {
        return TRUTHY_RETURN;
    }

    return { valid: false, text: i18n.t('creator.validation.expectedPhoneFormat') };
}

// Validates a Social Security Number (SSN)
function validateSocialSecurityNumber(value) {
    // If the value is null, return true
    if (!value) {
        return TRUTHY_RETURN;
    }
    const regex = /^(19|20)?(\d{6}([\s+-])\d{4}|(?!19|20)\d{10})$/;

    const validSSN = regex.test(value);
    if (validSSN) {
        return TRUTHY_RETURN;
    }

    return { valid: false, text: i18n.t('creator.validation.invalidSocialSecurity') };
}

// Validates whether the value is a string
export function validateIsString(value) {
    const string = typeof value === 'string';
    if (string) {
        return TRUTHY_RETURN;
    }
    return { valid: false, text: i18n.t('creator.validation.expectedString') };
}

// Validates whether a value is required (not undefined, null, or empty string)
export function validateRequired(value) {
    const exists = value !== undefined && value !== null && value !== '';
    if (exists) {
        return TRUTHY_RETURN;
    }
    return { valid: false, text: i18n.t('creator.validation.valueRequired') };
}

// Validates a date and time value
export function validateDateTime(value) {
    if (value === null) {
        return { valid: false, text: i18n.t('creator.validation.invalidDate') };
    }

    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
        return { valid: false, text: i18n.t('creator.validation.invalidDate') };
    }

    return TRUTHY_RETURN;
}

// Validates an array of email addresses
export function validateEmail(value) {
    const INVALID_EMAIL = { valid: false, text: i18n.t('creator.validation.invalidEmail') };

    const emailRegex = /\S+@\S+\.\S+/;
    // If the input is null, return true
    if (!value) {
        return TRUTHY_RETURN;
    }

    // If the input is a single string
    if (typeof value === 'string') {
        const validEmail = emailRegex.test(value);
        if (validEmail) {
            return TRUTHY_RETURN;
        }
        return INVALID_EMAIL;
    }

    // If the input is an array of emails
    if (Array.isArray(value)) {
        for (const email of value) {
            if (!emailRegex.test(email)) {
                return INVALID_EMAIL;
            }
        }
        return TRUTHY_RETURN;
    }

    // If the input format is not recognized
    return INVALID_EMAIL;
}

// Validates the length of a TipTap content
export function validateTipTapLength(value) {
    if (!value?.content?.length) {
        return { valid: false, text: i18n.t('creator.validation.valueRequired') };
    }

    const MAX_LENGTH = 2000;
    if (value.content.length > MAX_LENGTH) {
        return { valid: false, text: i18n.t('creator.validation.tooLong') };
    }

    return TRUTHY_RETURN;
}

// Validates that a TipTap content does not contain images
function validateNoImages(value) {
    const imgTagRegex = /<img[^>]*>/gi;
    if (imgTagRegex.test(value?.content)) {
        return { valid: false, text: i18n.t('creator.validation.imagesNotAllowedInSms') };
    }
    return TRUTHY_RETURN;
}

export function getSubmitKey(formId) {
    switch (formId) {
        case formTypes.CREATE_INCOMING_EMAIL:
        case formTypes.CREATE_CLIENT: {
            return 'creator.create';
        }
        case formTypes.CREATE_EMAIL_TO_CASE:
        case formTypes.CREATE_SMS_TO_CASE:
        case formTypes.CREATE_SMS:
        case formTypes.CREATE_OUTGOING_EMAIL: {
            return 'creator.send';
        }
        case formTypes.MERGE_CLIENT:
        case formTypes.BIND_CONTACT: {
            return 'creator.save';
        }
        case formTypes.CREATE_CALL: {
            return 'creator.call';
        }

        default: {
            return 'creator.send';
        }
    }
}

export function executeCall(data) {
    if (!data) return;
    const { swyxNumber, number } = data;
    if (!(swyxNumber || number)) return;

    if (!clientIsUsingMac()) {
        document.location.href = `tloswyx:${swyxNumber}`;
    } else {
        document.location.href = `tel:${number}`;
    }
}

function clientIsUsingMac() {
    return /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);
}
