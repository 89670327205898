<template>
    <InfiniteMenu
        :headers="[]"
        :fetchFunction="getTemplates"
        :items="templates.casesTemplates"
        :limit="10"
        :emptyHeader="$t('emptyHeader')"
        :emptyMessage="$t('emptyMessage')"
        :closeMenuOnClick="closeMenuOnClick"
        @click-item="selectTemplate"
    >
        <slot>
            <div v-if="icon === ''" class="template-select">
                <div class="template-icon">
                    <IconButton icon="mdi-list-box" />
                </div>
                <div class="template-selected-text">
                    <span v-if="!selectedTemplateId">{{ $t('selectTemplate') }}</span>
                    <span v-else>{{ selectedTemplateName }}</span>
                </div>
            </div>
            <div v-else>
                <v-btn icon>
                    <v-icon class="icon">mdi-text-box-plus-outline</v-icon>
                </v-btn>
            </div>
        </slot>
        <template #item="{ item }">
            <div class="select-template-button">
                <IconButton icon="mdi-list-box" />
                <div class="truncate">{{ item.name || $t('noName') }}</div>
            </div>
        </template>
    </InfiniteMenu>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import { templateTypes } from '../../../enums/templates.enums';
    import InfiniteMenu from '@/components/Main/List/InfiniteMenu.vue';
    import IconButton from '@/components/Main/Buttons/Icon.vue';

    export default {
        name: 'TemplateSelect',

        components: {
            InfiniteMenu,
            IconButton,
        },

        props: {
            useSlot: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                default: '',
            },
            boolean: {
                type: Boolean,
                default: false,
            },
            dense: {
                type: Boolean,
                default: true,
            },
            value: {
                type: Number,
                default: 0,
            },
            templateLanguage: {
                type: String,
                default: null,
            },
            templateTypes: {
                type: Array,
                default: null,
            },
            categoryFilter: {
                type: Array,
                default: null,
            },
            queueId: {
                type: Number,
                default: null,
            },
            selectedTemplateId: {
                type: Number,
                default: null,
            },
            closeMenuOnClick: {
                type: Boolean,
                default: false,
            },
            emitGlobalMergeTags: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                selectProps: {},
                inputTemplateTypes: {
                    SMS_TEMPLATE_SELECT: templateTypes.SMS,
                    EMAIL_TEMPLATE_SELECT: templateTypes.EMAIL,
                    CONFIRMATION_TEMPLATE_SELECT: templateTypes.CONFIRMATION_EMAIL,
                    CHAT_TEMPLATE_SELECT: templateTypes.CHAT,
                    SIGNATURE_TEMPLATE_SELECT: templateTypes.SIGNATURE,
                    TEMPLATE_SELECT: null,
                },
                selectedTemplateName: '',
                templateMenuActive: false,
            };
        },

        computed: {
            ...mapState('Templates', ['templates']),
        },
        mounted() {
            this.init();
        },
        methods: {
            ...mapActions('Templates', [
                'templateByCategory',
                'getTemplateById',
                'transformContentMergeTags',
                'transformContentMergeTagsGlobal',
            ]),
            /**
             * Initializes the component by fetching the template details if a template ID is selected.
             * If `selectedTemplateId` is present, it retrieves the template using `getTemplateById` method
             * and sets the `selectedTemplateName` to the name of the fetched template.
             * The reason this exists is because the template name is not available when the component renders, only when we click on it.
             * To ensure we have the name of the selected template, we fetch the template details when the component is mounted.
             */
            async init() {
                if (this.selectedTemplateId) {
                    const selectedTemplate = await this.getTemplateById(this.selectedTemplateId);
                    this.selectedTemplateName = selectedTemplate.name;
                }
            },
            async selectTemplate(template) {
                const selectedTemplate = await this.getTemplateById(template.id);
                this.handleChange(selectedTemplate);
                this.selectedTemplateName = selectedTemplate.name;
            },

            getTemplates(pagination) {
                this.templateMenuActive = true;
                this.templateByCategory({
                    categoryId: this.inputTemplateTypes[this.templateTypes],
                    ...pagination,
                    language: this.templateLanguage,
                    queueIds: [this.queueId] || null,
                });
            },
            async handleChange(template) {
                if (this.emitGlobalMergeTags) {
                    const content = await this.transformContentMergeTags({ template, context: this.templateTypes });
                    this.$emit('input', content);
                    this.$emit('change', content);
                } else {
                    const content = await this.transformContentMergeTagsGlobal({
                        template,
                        context: this.templateTypes,
                    });
                    this.$emit('input', template);
                    this.$emit('change', template);
                }
                this.templateMenuActive = false;
            },
        },
    };
</script>

<style scoped>
    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
        display: inline-block;
    }
    .select-template-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        min-height: 45px;
        margin: 5px;
        background-color: white;
        border-radius: 6px;
    }
    .select-template-button:hover {
        background-color: var(--v-gray3-base);
    }

    .template-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 36px;
        background-color: var(--v-gray3-base);
        border-color: var(--v-gray3-base);
        width: 100%;
        min-height: 50px;
        cursor: pointer;
    }

    .template-icon {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .template-selected-text {
        display: flex;
        flex-grow: 1;
        text-align: center;
        padding-left: 10px;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "emptyHeader": "Inga mallar",
        "emptyMessage": "Gå till mallar för att skapa en ny",
        "selectTemplate": "Mall",
        "noName": "Inget namn"
    },

    "en": {
        "emptyHeader": "No templates",
        "emptyMessage": "Go to templates to create a new one",
        "selectTemplate": "Template",
        "noName": "No name"
    }
}
</i18n>
