<template>
    <button :class="{ assigned: isAssigned, selected: isSelected }" @click="$emit('click', item)">
        <!-- ICON -->
        <i class="mdi mdi-account"></i>
        <!-- NAME -->
        <truncate align="left">
            {{ item.name }}
        </truncate>

        <!-- INACTIVE CHIP -->
        <InactiveIndicator v-if="!item.active" />
    </button>
</template>
<script lang="ts">
    import { PropType, defineComponent } from 'vue';

    export default defineComponent({
        name: 'ClientFamilyItemV2',
        components: {
            InactiveIndicator: () => import('@/components/Contacts/InactiveIndicator.vue'),
        },
        props: {
            item: {
                type: Object as PropType<{ id: string; name: string; parent: string | null; active: boolean }>,
                required: true,
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
            isAssigned: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>
<style scoped lang="scss">
    button {
        position: relative;
        display: flex;
        padding: 12px 16px 12px 12px;
        align-items: center;
        gap: 4px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid var(--Color-Neutrals-70, #d9d9d9);

        color: var(--Color-Neutrals-10, #3f3f3f);

        /* Body/Body S 400 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */

        transition: background-color 0.2s ease;

        &:hover {
            background-color: var(--v-gray3-base);
        }

        /** SELECTED ITEM */
        &.selected {
            background: var(--Color-Neutrals-80, #f2f2f2);

            &::before {
                content: '';
                position: absolute;
                width: 3px;
                height: 32px;
                border-radius: 0px 4px 4px 0px;
                background: var(--Color-Neutrals-0, #000);
                top: 8px;
                left: 0;
            }
        }

        /** ICON */
        i {
            color: #3f3f3f;
            display: flex;
            width: 24px;
            height: 24px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }
</style>
