<template>
    <tooltip :content="tooltip">
        <button
            :class="buttonClass"
            :style="buttonStyle"
            :disabled="isDisabled"
            class="icon-button"
            @click="click"
            cy-data="create-comments-round-button"
        >
            <div><i class="mdi icon" :class="iconClass"></i></div>
        </button>
    </tooltip>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { TranslateResult } from 'vue-i18n';

    export default defineComponent({
        name: 'CreateCommentsRoundButton',

        props: {
            tooltip: {
                type: String as PropType<string | TranslateResult>,
                default: '',
            },

            icon: {
                type: String,
                default: 'mdi-plus',
            },

            activeIcon: {
                type: String,
                default: '',
            },

            iconColor: {
                type: String,
                default: 'var(--v-gray4-base)',
            },

            activeIconColor: {
                type: String,
                default: 'white',
            },

            active: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            background: {
                type: String,
                default: '#E1F1F5',
            },

            activeColor: {
                type: String,
                default: 'var(--v-primary-base)',
            },

            toggle: {
                type: Boolean,
                default: false,
            },

            clickable: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                isActive: this.active,
                isDisabled: this.disabled,
            };
        },

        computed: {
            buttonClass() {
                return {
                    'not-clickable': !this.clickable,
                };
            },

            buttonStyle() {
                return {
                    backgroundColor: this.isActive ? this.activeColor : this.background,
                    color: this.isActive ? this.activeIconColor : this.iconColor,
                };
            },

            iconClass() {
                if (this.activeIcon === '') {
                    return this.icon;
                }
                return this.isActive ? this.activeIcon : this.icon;
            },
        },

        methods: {
            click() {
                if (this.isDisabled) return;

                if (this.toggle) {
                    this.isActive = !this.isActive;
                    this.$emit('update:active', this.isActive);
                    return;
                }

                this.$emit('click');
            },
        },

        watch: {
            active(value: boolean) {
                this.isActive = value;
            },

            disabled(value: boolean) {
                this.isDisabled = value;
            },
        },
    });
</script>

<style scoped>
    .not-clickable {
        pointer-events: none;
    }

    .icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s ease;
        justify-self: center;
    }

    .icon-button:active {
        opacity: 0.8;
    }

    .icon-button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .icon-button.active {
        background: var(--v-primary-base);
    }

    .icon {
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
</style>
