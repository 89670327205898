import axios from 'axios';

function getChatStatus(caseId) {
    return axios.get(`/chats/${caseId}/status`);
}

function answerChat(payload) {
    return axios.post('/chatmanager/answer-case', payload);
}

export default {
    getChatStatus,
    answerChat,
};
