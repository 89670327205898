<template>
    <div class="comments" :style="commentStyle" :class="commentClass">
        <header class="comment-header comment-section">
            <div class="comment-avatar">
                <InternalType :absolute="false" :item="{ internalType: 'sms' }" />
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <div v-on="on">
                            <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                            <SystemAvatar v-else :size="32" />
                        </div>
                    </template>
                    <span>{{ username }}</span>
                </v-tooltip>
            </div>
            <section>
                <div class="comment-info">
                    <div class="comment-from text-truncate">{{ item.from }}</div>
                    <div class="comment-top-field comment-date text-truncate" :class="getChipClassName(item)">
                        <v-icon v-if="!item.sent && isBeforeSendTimeAndScheduled" size="medium" color="white">
                            mdi-timer-sand
                        </v-icon>

                        <v-progress-circular
                            v-if="!item.sent && !item.failed && isAfterSendDateTime"
                            color="var(-v-gray2-base)"
                            indeterminate
                            size="10"
                            width="2"
                        />
                        <v-icon v-if="item.failed" size="medium">mdi-alert</v-icon>
                        {{ item.failed ? $t('comment.failed') : commentDirection }}
                    </div>
                    <div class="comment-top-field text-truncate">{{ $t('comment.to') }} {{ item.receiver }}</div>

                    <span v-if="isBeforeSendTimeAndScheduled" class="comment-top-field comment-date text-truncate">
                        {{ formatDate(item.sendDateTime) }}
                    </span>

                    <span v-else class="comment-top-field comment-date text-truncate">
                        {{ formatDate(item.dateTime) }}
                    </span>
                </div>
            </section>
        </header>

        <section v-if="item.subject" class="comment-title comment-section comment-tries text-truncate">
            <div>{{ item.subject }}</div>
            <div>
                <span v-if="item.tries" class="comment-tries-text">{{ $t('comment.tries') }} {{ item.tries }}</span>
            </div>
        </section>
        <section class="comment-content">
            <div>{{ item.comment }}</div>
        </section>
        <section class="comment-action-buttons d-flex">
            <CommentActions :active="['answer-sms']" :item="item" />

            <section class="comment-right-actions">
                <AdminMenu :item="item" />
                <div v-if="item.tries && !item.sent" class="ml-auto">
                    <v-btn v-if="item.failed" class="mr-2" text icon color="red" @click="deleteComment">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-chip :outlined="true" v-bind="attrs" v-on="on" @click="resetTries">
                                <span class="text-truncate chip-action">{{ $t('comment.retry') }}</span>
                            </v-chip>
                        </template>
                        <span>
                            {{
                                item.failed
                                    ? $t('comment.retryTooltipFailed')
                                    : $t('comment.retryTooltipTrying', {
                                          time: $tc('comment.retryMinutes', Math.ceil(nextRetryAttemptInMinutes)),
                                      })
                            }}
                        </span>
                    </v-tooltip>
                </div>
            </section>
        </section>
    </div>
</template>
<script>
    import { format } from 'date-fns';
    import { millisecondsInMinute } from 'date-fns/constants';
    import { mapState } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';

    import { resetCommentTries, deleteFailedComment } from '@/api/comments/comments';
    import config from '@/app/config';

    import InternalType from '@/components/Cases/List/InternalType.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import CommentActions from '@/components/Cases/CaseComments/CommentActions.vue';
    import AdminMenu from '@/components/Cases/Comments/AdminMenu.vue';

    export default {
        components: {
            InternalType,
            SystemAvatar,
            CommonAvatar,
            CommentActions,
            AdminMenu,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                config,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                dateNow: (state) => state.Cases.dateNow,
            }),
            username() {
                if (!this.item.userId) {
                    return this.$t('comment.system');
                }
                if (this.item?.agentDeleted) {
                    return this.$t('global.deletedUser');
                }
                return this.item.userName;
            },
            commentStyle() {
                return {
                    justifySelf: this.item.outgoing ? 'end' : 'start',
                };
            },

            nextRetryAttemptInMinutes() {
                return (new Date(this.item.retryAt) - this.dateNow) / millisecondsInMinute;
            },

            commentClass() {
                return {
                    outgoing: this.item.outgoing,
                    incoming: !this.item.outgoing,
                };
            },

            commentDirection() {
                if (this.isScheduled) {
                    return this.$t('comment.scheduled');
                }

                if (!this.item.sent) {
                    return this.$t('comment.sending');
                }
                return this.item.outgoing ? this.$t('comment.outgoing') : this.$t('comment.incoming');
            },

            isScheduled() {
                return this.item.sendDateTime && new Date(this.item.sendDateTime) > new Date();
            },

            isAfterSendDateTime() {
                return this.item.sendDateTime && new Date(this.item.sendDateTime) < new Date();
            },

            isBeforeSendTimeAndScheduled() {
                return this.isScheduled && !this.isAfterSendDateTime;
            },
        },

        methods: {
            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            getChipClassName(item) {
                if (this.isBeforeSendTimeAndScheduled) {
                    return 'scheduled-chip';
                }
                if (item.failed) {
                    return 'failed-chip';
                }
                if (item.outgoing) {
                    return 'outgoing-chip';
                }
                return 'incoming-chip';
            },

            getDay,
            format,

            async resetTries() {
                try {
                    await resetCommentTries(this.item.id);
                } catch (error) {
                    console.error('Error resetting comment tries', error);
                    this.$toasted.show(this.$t('comment.retryError'), { type: 'error' });
                }
            },
            async deleteComment() {
                try {
                    await deleteFailedComment(this.item.id);
                } catch (error) {
                    console.error('Error deleting comment', error);
                    this.$toasted.show(this.$t('comment.deleteError'), { type: 'error' });
                }
            },
        },
    };
</script>
<style scoped lang="scss">
    .comment-right-actions {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .comment-tries {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .comment-action-buttons {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        display: flex;
        flex: 1;
        justify-content: space-between;
    }
    .comment-attachment {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .comment-top-field {
        font-size: 12px;
        gap: 4px;
        display: flex;
        align-items: center;
    }
    .comments {
        padding: 32px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        background-color: rgba(245, 245, 247, 0.53);
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }

    .comments-grid {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px 0px;
    }

    .comments-grid-header {
        text-align: left;
        font-size: 12px;
        color: var(--v-gray2-base);
    }

    .comments-grid-text {
        font-size: 12px;
        color: var(--v-gray4-base);
    }

    .medium-heading {
        font-size: 14px;
        font-weight: 500;
        color: var(--v-gray4-base);
        text-align: center;
        margin-bottom: 16px;
    }

    .comment-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        gap: 8px;
    }

    .comment-header {
        display: grid;
        grid-template-columns: auto 1fr;
        padding-bottom: 8px;
        gap: 8px;
    }

    .avatar-image {
        background-position: center;
        object-fit: cover;
    }

    .comment-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-date {
        justify-self: end;
    }

    .comment-from {
        color: var(--v-gray4-base);
        font-size: 1rem;
    }

    .comment-section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .comment-content {
        white-space: pre-wrap;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1rem;
        word-break: break-word;
    }

    .comment-title {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.3rem;
        font-weight: 300;
    }

    .comment-tries-text {
        font-size: 12px;
        color: var(--v-gray2-base);
    }
    .chip-action {
        color: var(--v-gray2-base);
        font-size: 12px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "outgoing": "Outgoing",
            "incoming": "Incoming",
            "sending": "Sending",
            "scheduled": "Scheduled",
            "tries": "Tries: ",
            "failed": "Failed",
            "retry": "Retry",
            "retryTooltipFailed": "Retry sending the email",
            "retryMinutes": "{n} minute | {n} minutes",
            "retryTooltipTrying": "Next attempt to send is in {time}, press to retry now",
            "retryError": "Couldn't reset tries, please try again later",
            "deleteError": "Couldn't delete the comment, please try again later"
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "outgoing": "Utgående",
            "incoming": "Inkommande",
            "sending": "Skickar",
            "scheduled": "Schemalagd",
            "tries": "Försök: ",
            "failed": "Misslyckades",
            "retry": "Försök igen",
            "retryTooltipFailed": "Försök att skicka e-postmeddelandet igen",
            "retryMinutes": "{n} minut | {n} minuter",
            "retryTooltipTrying": "Nästa försök att skicka är om {time}, tryck för att försöka nu",
            "retryError": "Kunde inte återställa försök, försök igen senare",
            "deleteError": "Kunde inte ta bort kommentaren, försök igen senare"
        }
    }
}
</i18n>
