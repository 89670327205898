<template>
    <section class="favourite-email">
        <i
            class="mdi icon"
            :class="favouriteClass"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @click.prevent.stop="toggleFavourite"
        ></i>
        <span>{{ item.emailAddress }}</span>
    </section>
</template>

<script>
    export default {
        name: 'FavouriteEmail',

        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                hover: false,
            };
        },

        computed: {
            favouriteClass() {
                if (this.hover) {
                    return 'mdi-star favourite';
                }

                return this.item.isFavourite ? 'mdi-star favourite' : 'mdi-star-outline not-favourite';
            },
        },

        methods: {
            toggleFavourite() {
                this.$emit('toggle-favourite', this.item);
            },
        },
    };
</script>

<style scoped>
    .favourite-email {
        display: grid;
        grid-template-columns: 48px 1fr;
        padding: 10px 0;
        border-bottom: 1px solid #e0e0e0;
        transition: background-color 0.3s;
        min-height: 60px;
        max-height: 60px;
    }

    .favourite-email:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .favourite-email:active {
        background-color: #e0e0e0;
    }

    .favourite-email > * {
        align-self: center;
    }

    .favourite-email > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        padding-right: 12px;
    }

    .icon {
        font-size: 14px;
        display: flex;
        justify-content: center;
    }

    .not-favourite {
        color: #9e9e9e;
    }
</style>
