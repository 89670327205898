<template>
    <section :key="clientId" class="client-children-container">
        <InfiniteMenu
            :headers="[]"
            :fetchFunction="fetchClientChildren"
            :items="clientsChildren"
            :fetchProps="{ id: clientId, clientTypeIds: selectedClientTypeIds }"
            :limit="30"
            :emptyHeader="$t('clientChildren.empty')"
            :emptyMessage="$t('clientChildren.emptyMessage')"
            @click-item="selectItem"
        >
            <template #filter>
                <header>
                    <ClientTypeMultiSelect v-model="selectedClientTypeIds" @change="handleClientTypeFilterChange" />
                </header>
            </template>
            <tooltip :content="$t('clientChildren.childs')">
                <secondary-action-button :dense="true">
                    <div>
                        <v-icon size="24">mdi-account-box</v-icon>
                    </div>
                </secondary-action-button>
            </tooltip>

            <template #item="{ item }">
                <section :key="item.id" class="dynamic-item" :class="isSelected(item) ? 'selected' : ''">
                    <i class="mdi mdi-account"></i>
                    <!-- <span>{{ item.name }}</span> -->
                    <truncate>{{ item.name }}</truncate>
                    <InactiveIndicator v-if="!item.active" class="inactive-indicator" />
                </section>
            </template>
        </InfiniteMenu>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import InfiniteMenu from '@/components/Main/List/InfiniteMenu.vue';
    import ClientTypeMultiSelect from '@/components/Main/Selects/ClientTypeMultiSelect.vue';

    export default {
        name: 'ClientChildren',

        components: {
            InfiniteMenu,
            ClientTypeMultiSelect,
            InactiveIndicator: () => import('@/components/Contacts/InactiveIndicator.vue'),
        },

        data() {
            return {
                selectedClientTypeIds: [],
            };
        },

        computed: {
            ...mapState('Client', ['clientsChildren', 'currentSelectedClient']),

            clientId() {
                return this.currentSelectedClient?.clientId;
            },
        },

        watch: {
            selectedClientTypeIds() {
                this.handleClientTypeFilterChange();
            },
        },

        methods: {
            ...mapActions('Client', ['getClientsChildren']),
            async fetchClientChildren(params) {
                this.getClientsChildren(params);
            },

            handleClientTypeFilterChange() {
                const params = {
                    page: 1,
                    limit: 30,
                    search: '',
                    id: this.clientId,
                    clientTypeIds: this.selectedClientTypeIds,
                };

                this.fetchClientChildren(params);
            },

            selectItem(item) {
                this.$emit('select-item', item);
            },

            isSelected(item) {
                return item.id === this.currentSelectedClient?.clientId;
            },
        },
    };
</script>

<style scoped lang="scss">
    .dynamic-item {
        padding: 8px 16px;
        border-bottom: 1px solid #ececec;
        font-size: 12px;
        min-height: 48px;
        transition: background-color 0.2s;
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 4px;
        color: var(--v-gray2-base);
        user-select: none;
        align-items: center;

        .inactive-indicator {
            margin-left: auto;
        }
    }

    .dynamic-item:hover {
        background-color: #f5f5f5;
        cursor: pointer;
        color: black;
    }

    .dynamic-item:active {
        background-color: #e0e0e0;
    }

    .dynamic-item > * {
        display: flex;
        align-items: center;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);

        padding: 8px 16px;
        border: 1px solid #ececec;
        border-radius: 25%;
        flex: 1;
        width: 100%;
    }

    .selected {
        background-color: var(--v-primary-base);
        color: white;
    }

    .selected:hover {
        background-color: var(--v-primary-darken1);
        color: white;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientChildren": {
            "childs": "Contacts",
            "empty": "No contacts",
            "emptyMessage": "This client has no contacts",
            "couldNotFetch": "Could not fetch contacts"
        }
    },
    "sv": {
        "clientChildren": {
            "childs": "Kontakter",
            "empty": "Inga kontakter",
            "emptyMessage": "Denna klient har inga kontakter",
            "couldNotFetch": "Kunde inte hämta kontakter"
        }
    }
}
</i18n>
