import system from '@/api/system/system';
import vuetify from '@/plugins/vuetify';
import * as Sentry from '@sentry/vue';
import { themeColors } from '@/templates/colors.template';
import i18n from '../../i18n';
import { performActions } from '../../helpers/system.helper';
import { isValidHexCode } from '../../utils/Colors';

// State object
const state = {
    userSettings: null,
    searchKeys: {},
    serverTz: {},
    fetchKeys: [],
    standardApps: [],
    localTimezoneOffset: new Date().getTimezoneOffset(),
};

const mutations = {
    SET_CUSTOMER_SETTINGS(state, data) {
        state.userSettings = data;
    },
    CLEAR_CUSTOMER_SETTINGS(state) {
        state.userSettings = {};
    },
    UPDATE_CUSTOMER_SETTINGS(state, data) {
        state.userSettings = data;
    },
    SET_SEARCH_KEYS(state, data) {
        state.searchKeys = data;
        const keys = Object.values(data.keys);
        state.fetchKeys = keys
            .filter((el) => {
                if (el.includedInSeparateFetch === true) {
                    return el.DisplayText;
                }
                return false;
            })
            .map((el) => {
                return el.DisplayText;
            });
    },
    CLEAR_SEARCH_KEYS(state) {
        state.searchKeys = {};
    },
    UPDATE_SEARCH_KEYS(state, data) {
        state.searchKeys = data;
        const keys = Object.values(data.keys);
        state.fetchKeys = keys
            .filter((el) => {
                if (el.includedInSeparateFetch === true) {
                    return el.DisplayText;
                }
                return false;
            })
            .map((el) => {
                return el.DisplayText;
            });
    },
    SET_SERVER_TIMEZONE(state, data) {
        state.serverTz = data;
    },
    CLEAR_SERVER_TIMEZONE(state) {
        state.serverTz = {};
    },
    SET_STANDARD_APPS(state, data) {
        state.standardApps = data;
    },
};
// Actions
const actions = {
    SOCKET_settingsUpdate({ commit }, data) {
        const settings = structuredClone(state.userSettings);
        const newSettings = updateNestedValues(settings, data.route, data.keysToChange);
        commit('SET_CUSTOMER_SETTINGS', newSettings);
    },
    setVuetifyColors({ state }) {
        try {
            const { colors } = state.userSettings.appearance;
            const primaryColor = colors.primary.value;
            const secondaryColor = colors.secondary.value;

            // Validate primary color
            if (!isValidHexCode(primaryColor) || !isValidHexCode(secondaryColor)) {
                throw new Error('Invalid Color');
            }

            // Set colors
            vuetify.framework.theme.themes.light.primary = primaryColor;
            vuetify.framework.theme.themes.light.secondary = secondaryColor;
        } catch (error) {
            console.error('Error setting colors', error);
            Sentry.captureException(error);
        }
    },
    resetVuetifyColors() {
        try {
            vuetify.framework.theme.themes.light.primary = themeColors.primary;
            vuetify.framework.theme.themes.light.secondary = themeColors.secondary;
        } catch (error) {
            console.error('Error resetting colors', error);
            Sentry.captureException(error);
        }
    },
    async getSettings({ commit, dispatch }, configKeys) {
        if (!this.state.Auth.isLoggedIn) {
            throw new Error('Could not get settings - not logged in');
        }
        try {
            const res = await system.getSettings(configKeys);
            await performActions(res.data, commit, dispatch);
            return res.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    },
    updateField(_, payload) {
        return new Promise((resolve, reject) => {
            system
                .updateField(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('systemStore.chSaved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('systemStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    updateDoc({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            system
                .updateDoc(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('systemStore.chSaved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    dispatch('getSettings', payload.name);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('systemStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getUserNotices() {
        return new Promise((resolve, reject) => {
            system
                .getUserNotices()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    closeUserNotice(_, payload) {
        return new Promise((resolve, reject) => {
            system
                .closeUserNotice(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getStandardApps({ commit }) {
        try {
            const res = await system.getStandardApps();
            commit('SET_STANDARD_APPS', res.data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('systemStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },

    async getServerTZOffset({ commit }) {
        const res = await system.getServerTZOffset();
        commit('SET_SERVER_TIMEZONE', res.data);
        return res.data;
    },

    async getSentryEnabled() {
        try {
            const res = await system.getSentryEnabled();
            return res.data.enabled;
        } catch (error) {
            Sentry.captureException(error);
            return false;
        }
    },
};

const getters = {
    isDifferentTimezone(state) {
        const offset = state.serverTz?.offset;
        if (offset === null || offset === undefined) {
            return null;
        }
        return state.localTimezoneOffset !== state.serverTz.offset;
    },
};

function updateNestedValues(obj, keyPath, updates) {
    const keys = keyPath.split('.');
    let currentObj = obj;

    for (const key of keys.slice(0, -1)) {
        if (!(key in currentObj)) {
            throw new Error(`Intermediate key '${key}' does not exist.`);
        }
        // Move to the next nested object
        currentObj = currentObj[key];
    }

    // Update the value at the final key in the path
    if (!(keys.at(-1) in currentObj)) {
        throw new Error(`End key '${keys.at(-1)}' does not exist.`);
    }

    const nestedObj = currentObj[keys.at(-1)];
    for (const [innerKey, innerValue] of Object.entries(updates)) {
        nestedObj[innerKey] = innerValue;
    }
    return obj;
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
